import AnalysisCard from "../ToolsCard.vue";
import NeoButton from "@/components/button";
import Badge from "@/components/badge";
import NeoInput from "@/components/input";
import NeoLoader from "@/components/loader";
import osintAxios from "@/axios/osint.js";
import NeoTab from "@/components/tab";
import Treeview from "@/components/treeview";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {EventBus} from "@/main.js";
import axios, {CancelToken} from "@/axios";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "@/utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import JsonCSV from "vue-json-csv";
import SearchEngineCard from "@/views/research/tools/search-engine/search-engine-card";
import searchEngineCheatSheet from "@/views/research/tools/search-engine/search-engine-cheatsheet";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
import {monitoringSearchData, getMarkAsDone} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import AnalyseModal from "./analyse-modal";
import UngroupedFilter from "@/components/ungrouped-filter";
import ribbon from "@/containers/ribbon";
import {TippyComponent} from "vue-tippy";
import {cloneDeep, get, groupBy, isEqual, debounce} from "lodash";
import NeoBadgeList from "@/components/badge-list";
import NeoPagination from "@/components/pagination";
import FilterComponent from "@/components/filter-component";
import {FEATURES} from "@/utils/constants";
import toggleSwitch from "@/components/toggle-switch";
import NLPAnalysisCollection from "@/utils/content-scripts/nlp-analysis.collection";

let cancelPostRequest;

export default {
    name: "search-engine",
    mixins: [toolsHelper],
    components: {
        FilterComponent,
        tippy: TippyComponent,
        UngroupedFilter,
        AnalysisCard,
        NeoButton,
        "neo-badge": Badge,
        NeoInput,
        NeoLoader,
        NeoTab,
        ribbon,
        SearchEngineCard,
        Treeview,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "download-csv": JsonCSV,
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        AnalyseModal,
        // NeoGenericModal,
        NeoBadgeList,
        searchEngineCheatSheet,
        NeoPagination,
        dropdown: VueMultiselect,
        toggleSwitch,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        monitoringQueries: {
            type: Array,
            default: () => [],
        },
        isMonitoring: {
            type: Boolean,
            default: false,
        },
    },
    validations: {
        fNameInput: {
            required,
        },
        lNameInput: {
            required,
        },
    },
    data() {
        return {
            fetchedFilteredPages: [],
            analysisCardCount: null,
            getTotalPagesFilter: null,
            treeData: [],
            toggleFilter: false,
            filters: [
                {name: "person", title: "Persons", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/people.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "organisation", title: "Companies", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/companies.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "location", title: "Locations", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/locations.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "risks", title: "Risks", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/risks.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "matchingIdentifiers", title: "Matching Identifiers", searchedTerm: "", data: [0, 100], svg: require("@/assets/icons/filter-component/matching-identifiers.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "internetQueries", title: "Internet Queries", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/internet.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
                {name: "topicClassification", title: "Topic Classification", searchedTerm: "", data: [], svg: require("@/assets/icons/filter-component/topic-classification.svg"), dataFetched: false, page: 1, limit: 6, selectAll: false},
            ],
            expandSection: true,
            isOsintFailed: false,
            case_id: "",
            media_types: ["active_media", "passive_media", "search"],
            isLoading: false,
            searchKeyword: "",
            resultData: {},
            lazyLoading: false,
            showPreviewModal: false,
            previewImage: null,
            previewItem: null,
            showUngroupedFilterModal: false,
            tabs: [
                {key: "prebuilt", name: "Pre-Built Queries", enabled: true},
                {key: "manual", name: "Manual Queries", enabled: true},
                {key: "case_query", name: "Case Queries", enabled: true},
            ],
            currentTab: "prebuilt",
            fNameInput: "",
            mNameInput: "",
            lNameInput: "",
            nickNameInput: "",
            fullNameInput: "",
            locationInput: [],
            educationInput: [],
            employmentInput: [],
            rolesInput: [],
            hobbiesInput: [],
            companyInput: [],
            organizations: [],
            languageInput: [],
            LanguageInput: [],
            eventsInput: [],
            analysisStatusQueries: [],
            domainInput: "",
            websiteInput: "",
            infoIcon: "cheatSheet",
            saveSelectedQueries: false,
            autoAnalyzerId: "",
            steps: [
                {value: 1, label: "Entity Information", visited: true, submitted: false},
                {value: 2, label: "Queries", visited: false, submitted: false},
                {value: 3, label: "Analysis", visited: false, submitted: false},
            ],
            currentStep: 0,
            queriesSections: [
                {
                    label: "Identifier Analysis",
                    expanded: true,
                },
                {
                    label: "Risk Analysis",
                    expanded: true,
                },
                {
                    label: "Social Media Analysis",
                    expanded: true,
                },
                {
                    label: "ESG",
                    expanded: true,
                },
                {
                    label: "Other",
                    expanded: true,
                },
            ],
            displayQuery: {
                prebuilt: "",
                manual: "",
                case_query: "",
            },
            // languages: [],
            languages: [
                {
                    group: "Languages",
                    categories: [
                        {value: "English"},
                        {value: "Spanish"},
                        {value: "French"},
                        {value: "German"},
                        {value: "Russian"},
                        {value: "Italian"},
                        {value: "Swedish"},
                        {value: "Danish"},
                        {value: "Portuguese"},
                        {value: "Greek"},
                        {value: "Dutch"},
                        {value: "Polish"},
                        {value: "Arabic"},
                        {value: "Chinese-simplified"},
                        {value: "Chinese-traditional"},
                        {value: "Urdu"},
                        {value: "Japanese"},
                        {value: "Norwegian"},
                        {value: "Hindi"},
                        {value: "Bengali"},
                        {value: "Turkish"},
                        {value: "Thai"},
                        {value: "Korean"},
                        {value: "Serbian"},
                        {value: "Indonesian"},
                    ],
                },
            ],
            countriesOptions: [],
            entitiesList: [],
            selectedQuery: [],
            selectedLanguageFilter: [{value: "English"}],
            companyLanguageFilter: [{value: "English"}],
            negativeWordLanguages: [],
            selectedCountryFilter: [],
            showResultCount: null,
            companyCountryFilter: [],
            prebuiltData: {},
            prebuiltQueries: "",
            selectedCategory: {},
            expanded: false,
            clickedLinks: [],
            caseQueriesData: [],
            expandedCaseQueries: false,
            caseResultData: {},
            caseQuery: {},
            checkedQueries: [],
            tree: "",
            totalqueries: [],
            otherNameInput: [],
            otherCompanyNameInput: [],
            monitoringFilterVal: "all",
            selectvalue: false,
            runQuery: false,
            loadMore: false,
            information: [
                {
                    label: "Personal Information",
                    name: "person",
                    value: "person",
                },
                {
                    label: "Company Information",
                    name: "company",
                    value: "company",
                },
            ],
            selectedEntity: "person",
            monitoringFilterOptions: [
                {
                    group: "Monitoring",
                    categories: [
                        {
                            name: "All",
                            code: "all",
                            type: "MonitoringFilter",
                        },
                        {
                            name: "Monitoring",
                            code: true,
                            type: "MonitoringFilter",
                        },
                        {
                            name: "Not Monitoring",
                            code: false,
                            type: "MonitoringFilter",
                        },
                    ],
                },
            ],
            visitedFilterVal: "all",
            eventInput: [],
            autorun: false,
            companyEventInput: [],
            nlpCount: 0,
            visitedFilterOptions: [
                {
                    group: "Visited",
                    categories: [
                        {
                            name: "All",
                            code: "all",
                            type: "VisitedFilter",
                        },
                        {
                            name: "Visited",
                            code: true,
                            type: "VisitedFilter",
                        },
                        {
                            name: "Not Visited",
                            code: false,
                            type: "VisitedFilter",
                        },
                    ],
                },
            ],
            doneFilterVal: "all",
            doneFilterOptions: [
                {
                    group: "Done",
                    categories: [
                        {
                            name: "All",
                            code: "all",
                            type: "DoneFilter",
                        },
                        {
                            name: "Done",
                            code: true,
                            type: "DoneFilter",
                        },
                        {
                            name: "Not Done",
                            code: false,
                            type: "DoneFilter",
                        },
                    ],
                },
            ],
            emailInput: "",
            companyemailInput: "",
            phoneInput: "",
            companyphoneInput: "",
            usernameInput: "",
            selectedItem: {
                email: null,
                phone: null,
                username: null,
                otherName: [],
                location: [],
                education: [],
                employment: [],
                hobby: [],
                company: [],
                domain: null,
                events: [],
            },
            openDropdown: false,
            selectedCompanyItem: {
                email: null,
                phone: null,
                location: [],
                website: [],
                domain: null,
                events: [],
                otherCompanyName: [],
            },
            usernameList: [],
            phoneList: [],
            emailList: [],
            locationList: [],
            employmentList: [],
            educationList: [],
            domainList: [],
            companyUsers: [],
            organisationUsers: [],
            hobbyList: [],
            websiteList: [],
            companyLocationInput: [],
            // buildQueries: false,
            count: 0,
            analysisToolData: [],
            tabName: "",
            selectVal: {},
            selectCompanyVal: {},
            catval: [],
            queriesObj: {},
            productCategories: [],
            highlighting: false,
            treeViewLoading: true,
            searchEngineQueriesLoading: false,
            grouped: false,
            groupedResults: [],
            isQueryLoading: false,
            groupedStructure: false,
            isClusteringStatus: false,
            stopPolling: false,
            start_date: null,
            end_date: null,
            isPlaceholderLoading: false,
            placeHolders: null,
            prebuiltClicked: false,
            FilterCategory: {
                organisations: true,
                locations: true,
                persons: true,
            },
            run_status: "run",
            queriesExist: false,
            params: {
                tags: [],
                persons: [],
                locations: [],
                organisations: [],
                adverse: false,
            },
            selectedSaveQueries: true,
            querySetInfo: {},
            loadingState: "Loading...",
            page_number: 1,
            total_rows: 1,
            multiQueryResultLoading: false,
            fetchedPages: [],
            viewAllClicked: false,
            manualSearchQuery: "",
            isDoneLoading: false,
            manual_queryId: "",
            searchAllNext: {
                query: "",
                value: 1,
            },
            deepScanManual: false,
            deepScanManualDone: false,
            totalResults: "",
            savedTopicData: [],
            savedInternetQueries: [],
            filtersApplied: false,
            startAnalyzerId: [],
            handelUpdateStatus: {
                tree: null,
            },
            resultsCount: null,
            queriesCount: 0,
            analyzerOptions: [],
            selectedBuildQueries: [],
            headers: ["Query", "Query Count", "Analysis Credit", "Total Count"],
            creditList: [],
            creditinfo: false,
            searchEngineQueries: [],
            allCategoriesExpanded: true,
            selectedEntityId: {},
            queryGenerator: {},
            caseEntityId: [],
            entity: [],
            tabChanged: false,
            negativeLanguages: true,
            inputs: false,
            analysisStatus: [],
            analyzerCount: 0,
            buildQueryGenerator: {},
        };
    },
    created() {
        EventBus.$on("nlpDone", (data) => {
            if (this.currentTab === "prebuilt" || this.currentTab === "case_query" || this.currentTab == "manual") {
                if (this.prebuiltData.data) {
                    this.prebuiltData.data.map((el) => {
                        if (!el.analysis_status || el.analysis_status != "pending") {
                            let array = [];
                            if (el.esg_category_recognition) {
                                array.push("esg_category_recognition");
                            }
                            if (el.entity_recognition) {
                                array.push("entity_recognition");
                            }
                            if (el.multilabel) {
                                array.push("multilabel");
                            }
                            if (el.risk_category_recognition) {
                                array.push("risk_category_recognition");
                            }
                            el.analysis_remaining = this.getAnalyzerOption.length - array.length;
                        }
                    });
                }
                if (this.caseResultData.data) {
                    this.caseResultData.data.map((el) => {
                        if (el.analysis_status != "pending") {
                            let array = [];
                            if (el.esg_category_recognition) {
                                array.push("esg_category_recognition");
                            }
                            if (el.entity_recognition) {
                                array.push("entity_recognition");
                            }
                            if (el.multilabel) {
                                array.push("multilabel");
                            }
                            if (el.risk_category_recognition) {
                                array.push("risk_category_recognition");
                            }

                            el.analysis_remaining = this.getAnalyzerOptions.filter((res) => res.selected).length - array.length;
                        }
                    });
                }
                if (this.resultData.data) {
                    this.resultData.data.map((el) => {
                        if (el.analysis_status != "pending" || !el.analysis_status) {
                            let array = [];
                            if (el.esg_category_recognition) {
                                array.push("esg_category_recognition");
                            }
                            if (el.entity_recognition) {
                                array.push("entity_recognition");
                            }
                            if (el.multilabel) {
                                array.push("multilabel");
                            }
                            if (el.risk_category_recognition) {
                                array.push("risk_category_recognition");
                            }
                            el.analysis_remaining = this.getAnalyzerOptions.length - array.length;
                        }
                    });
                }
            }
        });
        this.getAnalyzerOptions.forEach((res) => {
            if (this.permissionExists(res.key)) {
                res.analysis = false;
                this.analyzerOptions.push(res);
            }
        });
        let data = [
            {analysis: false, name: "Case Identifiers", payload_name: "case_identifier", key: "analyzer-case-identifier"},
            {analysis: false, name: "Product Keywords", payload_name: "product_keywords", key: "analyzer-product-keywords"},
            {analysis: false, name: "Negative Words", payload_name: "negative_words", key: "analyzer-negative-words"},
        ];

        data.forEach((el) => {
            if (this.permissionExists(el.key)) {
                this.analyzerOptions.push(el);
            }
        });
    },
    computed: {
        ...mapGetters([
            "getReadOnlyMode",
            "getResearchData",
            "getAllToolsData",
            "getAnalysisToolData",
            "getCaseUsernames",
            "getCaseEmails",
            "getCaseLocations",
            "getCasePhones",
            "getCaseEmployments",
            "getCaseEducations",
            "getCaseHobbies",
            "getCaseCompanies",
            "getCaseOrganisations",
            "getCaseDomains",
            "buildQueries",
            "getAllMonitoringData",
            "getMonitoringDateFilter",
            "monitoringTools",
            "productMonitoring",
            "getSelectedToolQuery",
            "getProduct",
            "queriesLoading",
            "getSocketIO",
            "queryMonitoringEvents",
            "selectedToolsUsers",
            "getAnalyzerOptions",
            "getPermissions",
            "getAutoAnalyze",
        ]),
        getSelectedLanguage() {
            if (this.selectedLanguageFilter.length > 0) {
                let selectedLanguages = this.selectedLanguageFilter.map((elem) => elem.value);
                return selectedLanguages;
            }
        },

        caseIdentifiersChecked: {
            get: function() {
                return this.$store.getters.getCaseIdentifiersChecked;
            },
            set: function(value) {
                this.$store.dispatch("setCaseIdentifiersChecked", value);
            },
        },

        queriesExists() {
            return this.prebuiltQueryGen.children.some((el) => el.children.length > 0);
        },

        getBadWordsLanguages() {
            let data = this.negativeWordLanguages.length > 0 ? this.negativeWordLanguages?.map((elem) => elem.value) : [];
            return data.length > 0 ? data : ["All Languages"];
        },
        badWordsChecked: {
            get: function() {
                return this.$store.getters.getBadWordsChecked;
            },
            set: function(value) {
                this.$store.dispatch("setBadWordsChecked", value);
            },
        },
        productKeywordsChecked: {
            get: function() {
                return this.$store.getters.getProductKeywordsChecked;
            },
            set: function(value) {
                this.$store.dispatch("setProductKeywordsChecked", value);
            },
        },
        searchQueriesExits() {
            for (var i = 0; i < this.prebuiltQueryGen.children.length; i++) {
                let select = this.prebuiltQueryGen.children[i].children.some((obj) => obj.selected === true);
                if (select) return false;
                else return true;
            }
        },

        getSelectedCompany() {
            if (this.selectedItem["company"]?.length > 0) {
                let selectedCompanies = this.selectedItem["company"].map((elem) => elem.value);
                return selectedCompanies;
            }
        },

        getMoreBtnClasses() {
            let classes = "tw-px-2 tw-py-1 tw-outline-none tw-border-none tw-bg-blue-50  tw-text-sm";
            if (this.isLoading) classes += "tw-cursor-not-allowed hover:tw-bg-blue-50 hover:tw-text-blue-400";
            else classes += "hover:tw-bg-blue-100 hover:tw-text-blue-600 tw-cursor-pointer";
            return classes;
        },

        query() {
            return this.currentTab == "prebuilt" ? this.displayQuery.prebuilt : this.currentTab == "manual" ? this.displayQuery.manual : this.displayQuery.case_query;
        },
        getPrebuiltLastIndex() {
            return this.prebuiltData?.data[this.prebuiltData?.data.length - 1]._id;
        },
        paginatedSearchedData() {
            if (this.currentTab === "case_query" && !this.isMonitoringTab) {
                return this.searchedData.filter((ele) => ele.page === this.page_number);
            } else if (this.filtersApplied) {
                return this.searchedData.filter((ele) => ele.page === this.page_number);
            } else {
                return this.searchedData.slice(10 * (this.page_number - 1), this.page_number * 10);
            }
        },

        entityInformationExists() {
            if (this.selectedEntity === "person" && ((this.fNameInput.trim() !== "" && this.lNameInput.trim() !== "") || this.fullNameInput?.trim() !== "" || this.emailInput?.trim() !== "" || this.phoneInput?.trim() !== "")) {
                return false;
            } else if ((this.selectedEntity === "company" && this.selectedItem["company"].length > 0) || this.companyemailInput?.trim() !== "" || this.companyphoneInput?.trim() !== "") return false;
            else return true;
        },
        searchedData() {
            if (this.currentTab === "prebuilt") {
                this.prebuiltData?.data?.forEach((data) => {
                    let match = this.getResearchData?.media.findIndex((media) => {
                        return media.url === data.link;
                    });
                    if (match > -1) {
                        data.is_adverse = this.getResearchData?.media[match].is_adverse;
                        data.mediaType = this.getResearchData?.media[match].media_type
                            .toLowerCase()
                            .split("_")
                            .map(function(word) {
                                return word.charAt(0).toUpperCase() + word.slice(1);
                            })
                            .join(" ");
                    }
                });
                // this.$emit("searchResult", { data: this.prebuiltData?.data, source: "search-engine", searchType: "query" });
                // return this.prebuiltData?.data;
                return this.prebuiltData?.data?.map((el) => ({
                    ...el,
                    ...{
                        visited: el.visited || false,
                        done: el.done || false,
                        query_id: this.tree.id,
                    },
                }));
            } else if (this.currentTab === "manual") {
                this.resultData?.data?.forEach((data) => {
                    let match = this.getResearchData?.media.findIndex((media) => {
                        return media.url === data.link;
                    });
                    if (match > -1) {
                        data.is_adverse = this.getResearchData?.media[match].is_adverse;
                        data.mediaType = this.getResearchData?.media[match].media_type
                            .toLowerCase()
                            .split("_")
                            .map(function(word) {
                                return word.charAt(0).toUpperCase() + word.slice(1);
                            })
                            .join(" ");
                    }
                });
                // this.$emit("searchResult", { data: this.resultData?.data, source: "search-engine", searchType: "query" });
                return this.resultData?.data;
            } else {
                let caseData = this.caseResultData?.data;
                // if (this.doneFilterVal == "all" || this.visitedFilterVal == "all") {
                //     caseData = caseData;
                // }
                // if (this.doneFilterVal == "done") {
                //     caseData = caseData?.filter((ele) => ele.done);
                // }
                // if (this.visitedFilterVal == "visited") {
                //     caseData = caseData?.filter((ele) => ele.visited);
                // }
                // if (this.doneFilterVal == "not_done") {
                //     caseData = caseData?.filter((ele) => !ele.done);
                // }
                // if (this.visitedFilterVal == "not_visited") {
                //     caseData = caseData?.filter((ele) => !ele.visited);
                // }
                caseData?.forEach((data) => {
                    let match = this.getResearchData?.media.findIndex((media) => {
                        return media.url === data.link;
                    });
                    if (match > -1) {
                        // data.is_adverse = true;
                        // data.mediaType = "passive media".toLowerCase().split('_').map(function (word) {
                        //     return (word.charAt(0).toUpperCase() + word.slice(1));
                        // }).join(' ');
                        data.is_adverse = this.getResearchData?.media[match].is_adverse;
                        data.mediaType = this.getResearchData?.media[match].media_type
                            .toLowerCase()
                            .split("_")
                            .map(function(word) {
                                return word.charAt(0).toUpperCase() + word.slice(1);
                            })
                            .join(" ");
                    }
                });
                return caseData?.map((el) => ({
                    ...el,
                    ...{
                        visited: el.visited || false,
                        done: el.done || false,
                        query_id: this.tree.id,
                    },
                }));
            }
        },

        csvData() {
            let data = cloneDeep(this.searchedData);
            let that = this;
            data.forEach((el) => {
                el.visited = that.clickedLinks.find((ele) => ele.links.includes(el.link)) ? true : false;
                el.done = that.checkedInput(el, that.clickedLinks) ? true : false;
            });
            return this.convertToCSV(data.filter((el) => el._id));
        },

        csvFields() {
            return ["thumbnail", "visited", "link", "title", "snippet", "_id", "mediaType", "done"];
        },

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        showExpandAll() {
            if (!this.fNameInput || !this.lNameInput || !this.selectedItem.company.length > 0) {
                this.expanded = false;

                this.$refs.searchEngine?.$children?.$children.forEach((ele) => {
                    ele.expanded = this.expanded;
                    if (ele.$children.length > 0) {
                        ele.$children.forEach((elem) => {
                            elem.expanded = this.expanded;
                        });
                    }
                    [...ele.$el.getElementsByClassName("panel expandible panel-transition")]?.forEach((e) => (e.style.maxHeight = this.expanded ? "fit-content" : "0"));
                });
            }
            return (this.fNameInput && this.lNameInput) || this.selectedItem.company.map((ele) => ele.value);
        },

        prebuiltQueryGen() {
            let tree;
            if (!this.saveSelectedQueries && Object.keys(this.queryGenerator).length === 0) {
                let queriesObj = this.queriesObj;
                tree = {
                    children: [
                        {text: "Identifier Analysis", children: []},
                        {text: "Risk Analysis", children: []},
                        {text: "Social Media Analysis", children: []},
                        {text: "ESG", children: []},
                        {text: "Other", children: []},
                    ],
                };
                this.productCategories.forEach((prodCat) => {
                    tree.children.forEach((child) => {
                        if (child.text === prodCat.category) {
                            child.children.push({
                                text: prodCat.name,
                                topCategory: prodCat.category,
                                count: queriesObj[prodCat.name].length,
                                selected: queriesObj[prodCat.name].length > 0 ? true : false,
                                children: queriesObj[prodCat.name].map((category) => {
                                    const countName = category.maskedQuery ? "maskedQuery" : "query";
                                    const obj = {
                                        text: category.maskedQuery ? category.maskedQuery : category.label,
                                        query: category.query,
                                        maskedQuery: category.maskedQuery,
                                        limit: this.fetchLimit(category, countName, "prebuilt"),
                                        category: prodCat.name,
                                        id: category._id,
                                        selected: true,
                                        value: [],
                                    };
                                    return obj;
                                }),
                            });
                        }
                    });
                });
                this.handleMaxHeight();
                // this.queriesSections = [];
                // tree.children.forEach((el) => {
                //     if (el.children.length > 0) this.queriesSections.push({label: el.text, expanded: true});
                // });
                // this.queriesSections = JSON.parse(JSON.stringify(this.queriesSections));
                return this.buildQueries ? tree : {children: Object.entries(queriesObj).map((n) => ({text: n[0]}))};
            } else {
                // this.queriesSections = [];
                // this.queryGenerator.children.forEach((el) => {
                //     if (el.children.length > 0) this.queriesSections.push({label: el.text, expanded: true});
                // });
                return this.queryGenerator;
            }

            //For expanded panel height update
        },
        checkChildExists() {
            let checkarray = this.prebuiltQueryGen?.children.some((el) => el.children?.length > 0);
            return checkarray;
        },
        async autorunstatus() {
            if (this.autoAnalyzerId) {
                const analysisInterval = setInterval(async () => {
                    let analyzerStatus = await axios.get(`/search-engine/auto-run-status/${this.autoAnalyzerId}`);
                    this.analysisStatusQueries = analyzerStatus.data.data.queries;

                    // this.selectedPrebuiltQueryGen.children.map((el) => {
                    //     el.children.map((child) => {
                    //         child.children.map((ch) => {
                    //             console.log(ch);
                    //             this.analysisStatusQueries.forEach((query) => {
                    //                 if (ch.id === query._id) {
                    //                     console.log(query._id);
                    //                     ch.dataLimit = query.result_count;
                    //                 }
                    //             });
                    //         });
                    //     });
                    // });

                    Object.keys(analyzerStatus.data.data.top_category_config).forEach((el) => {
                        this.analysisStatus.map((category) => {
                            if (el === category.category) category.value = analyzerStatus.data.data.top_category_config[el];
                        });
                    });
                    this.analysisStatus.forEach((el) => {
                        if (el.value !== "Pending") {
                            this.selectedPrebuiltQueryGen.children.map((el) => {
                                el.children.map((child) => {
                                    child.children.map((ch) => {
                                        this.analysisStatusQueries.forEach((query) => {
                                            if (ch.id === query._id && el.text === query.top_category) {
                                                ch.dataLimit = query.result_count;
                                            }
                                        });
                                    });
                                });
                            });
                        }
                    });

                    if (this.analysisStatus.every((el) => el.value !== "Pending")) {
                        clearInterval(analysisInterval);
                    }
                }, 3000);
            }
        },
        selectedPrebuiltQueryGen() {
            let tree1 = JSON.parse(JSON.stringify(this.queryGenerator));
            let data = tree1.children.filter((el) => el?.children.length !== 0);
            tree1.children = data;
            tree1.children.forEach((section) => {
                if (!this.analysisStatus.find((cat) => cat.category === section.text)) this.analysisStatus.push({category: section.text, value: ""});
                section.children.forEach((category) => {
                    const selected = category.children.filter((val) => val.selected);
                    category.children = selected.map((val) => ({
                        ...val,
                        limit: this.fetchLimit(val, val.maskedQuery ? "maskedQuery" : "query", "prebuilt"),
                    }));
                });
            });
            if (this.analysisStatusQueries.length > 0 && this.analysisStatus.every((el) => el.value !== "Pending")) {
                tree1.children.map((el) => {
                    el.children.map((child) => {
                        child.children.map((ch) => {
                            this.analysisStatusQueries.forEach((query) => {
                                if (ch.id === query._id) {
                                    ch.dataLimit = query.result_count;
                                }
                            });
                        });
                    });
                });
            }
            return tree1;
        },
        caseQueries() {
            let tree = {
                children: [
                    {text: "Identifier Analysis", children: []},
                    {text: "Risk Analysis", children: []},
                    {text: "Social Media Analysis", children: []},
                    {text: "ESG", children: []},
                    {text: "Other", children: []},
                ],
            };

            let selectedQueries = this.$refs.case_query?.selectedSearchQueries;
            this.productCategories.forEach((prodCat) => {
                tree.children.forEach((child) => {
                    if (child.text === prodCat.category) {
                        child.children.push({
                            text: prodCat.name,
                            children: this.caseQueriesData?.queries
                                ?.filter((n) => n.category == prodCat.name)
                                .map((ele) => {
                                    const countName = "label";
                                    const obj = {
                                        text: ele.maskedQuery ? ele.maskedQuery : ele.search_query,
                                        query: ele.search_query,
                                        id: ele._id,
                                        monitoring: ele.monitoring,
                                        monitoringSwitch: true,
                                        category: prodCat,
                                        maskedQuery: ele.maskedQuery,
                                        // isCheckbox: !this.isMonitoringTab,
                                        isCheckbox: false,
                                        checkedValue: ele.checked,
                                        label: ele.label,
                                        limit: `${ele.done}/${ele.count}`,
                                        total_results: ele.count,
                                        acknowledged_results: ele.done,
                                        saved_results: 0,
                                        unacknowledged_results: 0,
                                    };
                                    if (this.isMonitoringTab) {
                                        obj["eventCount"] = get(this.queryMonitoringEvents, ele._id, 0);
                                    }
                                    return obj;
                                }),
                        });
                    }
                });
            });

            let data = tree.children.filter((el) => el.children.length !== 0);
            tree.children = data;

            tree.children.push({
                text: "Other Case Query",
                children: this.caseQueriesData?.queries
                    ?.filter((n) => !n.category || n.category == "Other Case Query" || n.category == "Adverse")
                    .map((ele) => {
                        const countName = "label";
                        const obj = {
                            text: ele.label,
                            query: ele.search_query,
                            id: ele._id,
                            monitoring: ele.monitoring,
                            monitoringSwitch: false,
                            category: "Other Case Query",
                            maskedQuery: ele.masked_query,
                            isCheckbox: false,
                            label: ele.label,
                            limit: `${ele.done}/${ele.count}`,
                            total_results: ele.count,
                            acknowledged_results: ele.done,
                            saved_results: 0,
                            unacknowledged_results: 0,
                        };
                        if (this.isMonitoringTab) {
                            obj["eventCount"] = get(this.queryMonitoringEvents, ele._id, 0);
                        }
                        return obj;
                    }),
            });
            // tree.children?.forEach((elem, indx) => {
            //     tree.children[indx].children = tree.children[indx]?.children?.map((child) => ({
            //         ...child,
            //         monitoringSwitch: this.isMonitoringAuthorized,
            //         frequency: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.frequency,
            //         status: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.status,
            //         upcoming_review_day: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.upcoming_review_day,
            //         monitoring_id: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?._id,
            //     }));
            // });
            for (var i = 0; i < tree.children.length; i++) {
                if (tree.children[i].text === "Other Case Query") {
                    tree.children[i].children = tree.children[i]?.children?.map((child) => ({
                        ...child,
                        monitoringSwitch: this.isMonitoringAuthorized,
                        frequency: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.frequency,
                        status: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.status,
                        upcoming_review_day: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.upcoming_review_day,
                        monitoring_id: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?._id,
                    }));
                } else {
                    tree.children[i].children.forEach((elem, indx) => {
                        tree.children[i].children[indx].children = tree.children[i].children[indx]?.children?.map((child) => ({
                            ...child,
                            monitoringSwitch: this.isMonitoringAuthorized,
                            frequency: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.frequency,
                            status: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.status,
                            upcoming_review_day: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?.upcoming_review_day,
                            monitoring_id: this.monitoringQueries.find((mq) => mq?.query.query_id === child.id)?._id,
                        }));
                    });
                }
            }

            if (this.monitoringFilterVal === "monitoring") {
                for (var j = 0; j < tree.children.length; j++) {
                    if (tree.children[j].text === "Other Case Query") {
                        tree.children?.forEach((ele, index) => {
                            if (ele.text === "Other Case Query") {
                                tree.children[index].children = ele.children
                                    ?.filter((n) => n.monitoring)
                                    ?.sort((a, b) => {
                                        if (a.status === "enabled") return -1;
                                        else return 1;
                                    });
                            }
                        });
                    } else {
                        tree.children[j].children?.forEach((ele, index) => {
                            tree.children[j].children[index].children = ele.children
                                ?.filter((n) => n.monitoring)
                                ?.sort((a, b) => {
                                    if (a.status === "enabled") return -1;
                                    else return 1;
                                });
                        });
                    }
                }

                // tree.children?.forEach((ele, index) => {
                //     tree.children[index].children = ele.children
                //         ?.filter((n) => n.monitoring)
                //         ?.sort((a, b) => {
                //             if (a.status === "enabled") return -1;
                //             else return 1;
                //         });
                // });
            } else if (this.monitoringFilterVal === "not_monitoring") {
                tree.children[4]?.forEach((ele, index) => {
                    tree.children[index].children = ele.children?.filter((n) => !n.monitoring);
                });
                tree.children[0]?.children?.forEach((ele, index) => {
                    tree.children[0].children[index].children = ele.children?.filter((n) => !n.monitoring);
                });
                tree.children[1]?.children?.forEach((ele, index) => {
                    tree.children[1].children[index].children = ele.children?.filter((n) => !n.monitoring);
                });
                tree.children[2]?.children?.forEach((ele, index) => {
                    tree.children[2].children[index].children = ele.children?.filter((n) => !n.monitoring);
                });
                tree.children[3]?.children?.forEach((ele, index) => {
                    tree.children[3].children[index].children = ele.children?.filter((n) => !n.monitoring);
                });
            }
            // Licensing permission for monitoring switch
            //For expanded panel height update
            this.handleMaxHeight();

            let selected_id = selectedQueries?.map((selected) => {
                return selected.id;
            });
            let deep_copy = cloneDeep(tree);
            deep_copy?.children?.map((data) => {
                data.children?.map((val) => {
                    if (selected_id?.includes(val.id)) {
                        val.checkedValue = true;
                    }
                });
            });

            // for(var i=0;i<4;i++){
            //     deep_copy?.children[i].children?.map((data) => {
            //         data.children?.map((val) => {
            //             if (selected_id?.includes(val.id)) {
            //                 val.checkedValue = true;
            //             }
            //         });
            //     });
            // }

            return deep_copy;
        },
        loadMoreCond() {
            if (this.currentTab === "case_query") {
                if (this.visitedFilterVal == "all") {
                    return !this.lazyLoading;
                } else {
                    return false;
                }
            } else return !this.lazyLoading;
        },
        checkMarkAllDone() {
            if (!this.isMonitoringTab) {
                this.count = 0;
                let lengthCount = 0;
                this.paginatedSearchedData.forEach((ele) => {
                    if (ele.done || this.clickedLinks.find((elem) => elem.doneLinks.includes(ele.link))) {
                        this.count += 1;
                    }
                    lengthCount += 1;
                });
                return lengthCount === this.count;
            } else {
                return this.paginatedSearchedData.every((srcData) => srcData.changes_viewed);
            }
        },
        isMonitoringAuthorized() {
            return this.$store.getters.getPermissions.includes("search_engine__monitoring");
            // return true
        },
        isAutoRunAuthorized() {
            return this.$store.getters.getPermissions.includes("search_engine__auto_run");
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        breadCrumbTab() {
            return this.tabs.find((tab) => tab.key === this.currentTab).name;
        },

        resultCardCount() {
            let count = {
                grouped: 0,
                ungrouped: 0,
            };
            this.groupedResults?.flatMap((el) => {
                count.grouped += el.results.length;
            });
            count.ungrouped = this.searchedData?.length;
            return count;
        },
        getTotalPages() {
            return Math.ceil((this.currentTab === "case_query" && !this.isMonitoringTab ? this.total_rows : this.searchedData.length) / 10);
        },
        filterCount() {
            let count = 0;
            if (this.doneFilterVal !== "all") {
                count += 1;
            }
            if (this.visitedFilterVal !== "all") {
                count += 1;
            }
            if (this.monitoringFilterVal !== "all") {
                count += 1;
            }
            if (this.run_status !== "all") {
                count += 1;
            }
            if (this.start_date && this.end_date) {
                count += 1;
            }
            if (this.params.adverse) {
                count += 1;
            }
            return count;
        },
        showAnalyser() {
            return this.getAnalyzerOptions.filter((res) => res.selected);
        },
    },
    watch: {
        fNameInput() {
            this.showResult = false;
        },

        mNameInput() {
            this.showResult = false;
        },

        lNameInput() {
            this.showResult = false;
        },

        nickNameInput() {
            this.showResult = false;
        },

        fullNameInput() {
            this.showResult = false;
        },

        rolesInput() {
            this.showResult = false;
        },

        selectedItem: {
            handler(val) {
                this.showResult = false;
            },
            deep: true,
        },
        getProduct: {
            async handler(newValue) {
                if (newValue.key) {
                    await this.fetchCategoryProduct();
                }
            },
            deep: true,
        },
        async selectedToolsUsers(prevVal, newVal) {
            if (!isEqual(prevVal, newVal)) {
                await this.fetchCaseQueriesData();
            }
        },
        startAnalyzerId: {
            handler(newValue) {
                if (newValue && newValue.length > 0) {
                    this.startStatusInterval();
                }
            },
        },
    },
    async mounted() {
        // this.getSearchengineLanguages();
        this.tabName = this.$route.name;
        this.case_id = this.$route.params.id;
        this.organizations = [];
        const dataRes = this.getToolsData(this.tabName, "search-engine-tool");
        this.grouped = false;
        if (this.getProduct?.key) {
            await this.fetchCategoryProduct();
        }
        if (dataRes) {
            console.log(dataRes.selectedItem);
            this.isLoading = dataRes.isLoading;
            this.searchKeyword = dataRes.searchKeyword;
            this.resultData = dataRes.resultData;
            this.lazyLoading = dataRes.lazyLoading;
            this.tabs = dataRes.tabs;
            this.currentTab = dataRes.currentTab;
            this.fNameInput = dataRes.fNameInput;
            this.mNameInput = dataRes.mNameInput;
            this.lNameInput = dataRes.lNameInput;
            this.nickNameInput = dataRes.nickNameInput;
            this.fullNameInput = dataRes.fullNameInput;
            this.selectedEntity = dataRes.selectedEntity;
            // this.locationInput = dataRes.locationInput;
            // this.educationInput = dataRes.educationInput;
            // this.employmentInput = dataRes.employmentInput;
            this.rolesInput = dataRes.rolesInput;
            // this.hobbiesInput = dataRes.hobbiesInput;
            this.displayQuery = dataRes.displayQuery;
            this.prebuiltData = dataRes.prebuiltData;
            this.prebuiltQuery = dataRes.prebuiltQuery;
            this.expanded = dataRes.expanded;
            this.clickedLinks = dataRes.clickedLinks;
            this.caseQueriesData = dataRes.caseQueriesData;
            this.expandedCaseQueries = dataRes.expandedCaseQueries;
            this.caseResultData = dataRes.caseResultData;
            this.caseQuery = dataRes.caseQuery;
            this.tree = dataRes.tree;
            this.monitoringFilterVal = dataRes.monitoringFilterVal;
            this.monitoringFilterOptions = dataRes.monitoringFilterOptions;
            this.visitedFilterVal = dataRes.visitedFilterVal;
            this.visitedFilterOptions = dataRes.visitedFilterOptions;
            this.emailInput = dataRes.emailInput;
            this.phoneInput = dataRes.phoneInput;
            this.usernameInput = dataRes.usernameInput;
            this.companyInput = dataRes.companyInput;
            this.languageInput = dataRes.languageInput;
            this.domainInput = dataRes.domainInput;
            this.companyemailInput = dataRes.companyemailInput;
            this.companyphoneInput = dataRes.companyphoneInput;
            this.phoneList = dataRes.phoneList;
            this.emailList = dataRes.emailList;
            this.usernameList = dataRes.usernameList;
            this.educationList = dataRes.educationList;
            this.employmentList = dataRes.employmentList;
            this.hobbyList = dataRes.hobbyList;
            this.locationList = dataRes.locationList;
            this.domainList = dataRes.domainList;
            this.companyUsers = dataRes.companyUsers;
            this.queriesObj = dataRes.queriesObj;
            this.selectVal = dataRes.selectVal;
            this.selectCompanyVal = dataRes.selectCompanyVal;
            this.queriesObj = dataRes.queriesObj;
            this.productCategories = dataRes.productCategories;
            this.treeViewLoading = dataRes.treeViewLoading;
            this.searchEngineQueriesLoading = dataRes.searchEngineQueriesLoading;
            // this.grouped = dataRes.grouped;
            this.groupedResults = dataRes.groupedResults;
            this.isQueryLoading = dataRes.isQueryLoading;
            this.groupedStructure = dataRes.groupedStructure;
            this.isClusteringStatus = dataRes.isClusteringStatus;
            this.params = dataRes.params;
            this.checkedQueries = dataRes.checkedQueries;
            this.currentStep = dataRes.currentStep;
            this.nlpCount = dataRes.nlpCount;
            this.analyzerOptions = dataRes.analyzerOptions;
            this.runQuery = dataRes.runQuery;
            this.queryGenerator = dataRes.queryGenerator;
            this.steps = dataRes.steps;
            this.analyzerCount = dataRes.analyzerCount;
            this.selectedEntityId = dataRes.selectedEntityId;
            // this.caseQueries = dataRes.caseQueries;
            this.selectedItem["phone"] = dataRes.phoneInput
                ? {
                      value: dataRes.phoneInput,
                  }
                : this.selectedItem["phone"];
            this.selectedItem["email"] = dataRes.emailInput
                ? {
                      value: dataRes.emailInput,
                  }
                : this.selectedItem["email"];
            this.selectedCompanyItem["phone"] = dataRes.companyphoneInput
                ? {
                      value: dataRes.companyphoneInput,
                  }
                : this.selectedCompanyItem["phone"];
            this.selectedCompanyItem["email"] = dataRes.companyemailInput
                ? {
                      value: dataRes.companyemailInput,
                  }
                : this.selectedCompanyItem["email"];
            this.selectedCompanyItem["website"] = dataRes.websiteInput
                ? {
                      value: dataRes.websiteInput,
                  }
                : this.selectedCompanyItem["website"];
            this.selectedCompanyItem["domain"] = dataRes.domainInput
                ? {
                      value: dataRes.domainInput,
                  }
                : this.selectedCompanyItem["domain"];
            this.selectedItem["username"] = dataRes.usernameInput
                ? {
                      value: dataRes.usernameInput,
                  }
                : this.selectedItem["username"];
            this.selectedCountryFilter = dataRes.selectedCountryFilter ? dataRes.selectedCountryFilter : this.selectedCountryFilter;
            // this.selectedItem["company"] = dataRes.companyInput
            //     ? {
            //           value: dataRes.companyInput,
            //       }
            //     : this.selectedItem["company"];
            this.selectedItem["domain"] = dataRes.domainInput
                ? {
                      value: dataRes.domainInput,
                  }
                : this.selectedItem["domain"];
            if (Object.keys(this.selectedLanguageFilter).length === 1 && this.selectedLanguageFilter[0].value === "English") {
                this.selectedLanguageFilter = [];
                dataRes.languageInput = [{value: "English"}];
            }

            if (dataRes.companyInput?.length) this.selectedItem["company"] = [...this.selectedItem["company"], ...dataRes.companyInput];
            if (dataRes.languageInput?.length) this.selectedLanguageFilter = [...this.selectedLanguageFilter, ...dataRes.languageInput];
            if (dataRes.locationInput?.length) this.selectedItem["location"] = [...this.selectedItem["location"], ...dataRes.locationInput];
            if (dataRes.educationInput?.length) this.selectedItem["education"] = [...this.selectedItem["education"], ...dataRes.educationInput];
            if (dataRes.employmentInput?.length) this.selectedItem["employment"] = [...this.selectedItem["employment"], ...dataRes.employmentInput];
            if (dataRes.hobbiesInput?.length) this.selectedItem["hobby"] = [...this.selectedItem["hobby"], ...dataRes.hobbiesInput];
            if (dataRes.companyLocationInput?.length) this.selectedCompanyItem["location"] = [...this.selectedCompanyItem["location"], ...dataRes.companyLocationInput];
        }

        let obj = JSON.parse(sessionStorage.getItem("search-engine-inputs"));
        if (obj && obj.case_id == this.case_id) {
            this.fNameInput = obj.fNameInput;
            this.mNameInput = obj.mNameInput;
            this.lNameInput = obj.lNameInput;
            this.nickNameInput = obj.nickNameInput;
            this.fullNameInput = obj.fullNameInput;
            this.rolesInput = obj.rolesInput;
            this.locationInput = obj.locationInput;
            this.educationInput = obj.educationInput;
            this.employmentInput = obj.employmentInput;
            this.hobbiesInput = obj.hobbiesInput;
            this.emailInput = obj.emailInput;
            this.phoneInput = obj.phoneInput;
            this.usernameInput = obj.usernameInput;
            this.phoneList = obj.phoneList;
            this.emailList = obj.emailList;
            this.usernameList = obj.usernameList;
            this.educationList = obj.educationList;
            this.employmentList = obj.employmentList;
            this.hobbyList = obj.hobbyList;
            this.locationList = obj.locationList;
            this.domainList = obj.domainList;
            this.companyUsers = obj.companyUsers;
            this.companyInput = obj.companyInput;
            this.languageInput = obj.languageInput;
            this.selectedBuildQueries = obj.prebuildQueries;
            this.selectedEntity = obj.selectedEntity;
            this.companyemailInput = obj.companyemailInput;
            this.companyphoneInput = obj.companyphoneInput;
            this.companyLocationInput = obj.companyLocationInput;
            this.selectedEntityId = obj.selectedEntityId;
            this.selectedItem["phone"] = obj.phoneInput
                ? {
                      value: obj.phoneInput,
                  }
                : this.selectedItem["phone"];
            this.selectedCompanyItem["phone"] = obj.companyphoneInput
                ? {
                      value: obj.companyphoneInput,
                  }
                : this.selectedCompanyItem["phone"];
            this.selectedItem["email"] = obj.emailInput
                ? {
                      value: obj.emailInput,
                  }
                : this.selectedItem["email"];
            this.selectedCompanyItem["email"] = obj.companyemailInput
                ? {
                      value: obj.companyemailInput,
                  }
                : this.selectedCompanyItem["email"];
            this.selectedItem["username"] = obj.usernameInput
                ? {
                      value: obj.usernameInput,
                  }
                : this.selectedItem["username"];
            this.selectedItem["company"] = obj.companyInput
                ? {
                      value: obj.companyInput,
                  }
                : this.selectedItem["company"];
            this.selectedCountryFilter = obj.selectedCountryFilter ? obj.selectedCountryFilter : this.selectedCountryFilter;
            this.selectedItem["domain"] = obj.domainInput
                ? {
                      value: obj.domainInput,
                  }
                : this.selectedItem["domain"];
            this.selectedCompanyItem["domain"] = obj.domainInput
                ? {
                      value: obj.domainInput,
                  }
                : this.selectedCompanyItem["domain"];
            this.selectedCompanyItem["website"] = obj.websiteInput
                ? {
                      value: obj.websiteInput,
                  }
                : this.selectedCompanyItem["website"];
            if (typeof obj.companyInput === "string" || typeof obj.companyInput === "object") {
                let company;
                if (Object.keys(obj.companyInput).length === 1) company = Array.isArray(obj.companyInput) ? obj.companyInput : [obj.companyInput];
                else if (typeof obj.companyInput === "string") {
                    company = [{value: obj.companyInput}];
                } else company = obj.companyInput;
                this.selectedItem["company"] = [...company];
            } else {
                if (obj.companyInput?.length) this.selectedItem["company"] = [...obj.companyInput];
            }
            if (obj.locationInput?.length)
                this.selectedItem["location"] = [
                    // ...this.selectedItem['location'],
                    ...obj.locationInput,
                ];
            if (obj.companyLocationInput?.length)
                this.selectedCompanyItem["location"] = [
                    // ...this.selectedItem['location'],
                    ...obj.companyLocationInput,
                ];
            if (obj.educationInput?.length)
                this.selectedItem["education"] = [
                    // ...this.selectedItem['education'],
                    ...obj.educationInput,
                ];
            if (obj.employmentInput?.length)
                this.selectedItem["employment"] = [
                    // ...this.selectedItem['employment'],
                    ...obj.employmentInput,
                ];
            if (obj.hobbiesInput?.length)
                this.selectedItem["hobby"] = [
                    // ...this.selectedItem['hobby'],
                    ...obj.hobbiesInput,
                ];
            this.selectedLanguageFilter = [];
            if (obj.languageInput?.length) this.selectedLanguageFilter = [...obj.languageInput];
            else this.selectedLanguageFilter = [{value: "English"}];
            let step = JSON.parse(sessionStorage.getItem("search-engine-steps"));
            this.currentStep = step?.currentStep ? step.currentStep : 0;
            this.steps = step?.steps ? step.steps : this.steps;
            let query = JSON.parse(sessionStorage.getItem("search-engine-selected-queries"));
            if (query) {
                this.queryGenerator = query.prebuildQueries;
                this.selectedSaveQueries = query.selectedSaveQueries;
                this.autorun = query?.autorun ? query.autorun : false;
            }
            let selectedQueries = JSON.parse(sessionStorage.getItem("search-engine-build-queries"));
            this.buildQueryGenerator = selectedQueries?.selectedQueries ? selectedQueries?.selectedQueries : {};
            this.runQuery = selectedQueries?.runQuery && this.currentStep === 2 ? selectedQueries?.runQuery : false;
            this.autoAnalyzerId = selectedQueries?.autoAnalyzerId ? selectedQueries?.autoAnalyzerId : "";
            let analyzer = this.analyzerOptions;
            if (selectedQueries?.analyzerOptions) {
                this.analyzerOptions = [];
                analyzer.forEach((options) => {
                    let index = this.analyzerOptions.findIndex((opt) => opt.name === options.name);
                    selectedQueries.analyzerOptions.map((selected) => {
                        if (selected.name === options.name) {
                            this.analyzerOptions.push(selected);
                        } else if (index > -1) {
                            this.analyzerOptions.push(options);
                        }
                    });
                });
            }
            if (this.autorun) await this.autorunstatus;
            this.queriesExist = selectedQueries?.buildQueries ? selectedQueries?.buildQueries : false;
            this.nlpCount = selectedQueries?.nlpCount ? selectedQueries?.nlpCount : 0;
        }
        //Handling of Tab(Monitoring and tools) changing...
        if (this.tabName !== "tools") {
            this.currentTab = "case_query";
            if (this.tabName === "tools-ro") {
                this.monitoringFilterVal = "all";
            } else this.monitoringFilterVal = "monitoring";
        }
        if (this.currentTab === "case_query") {
            this.clickedLinks = [];
            this.isLoading = true;
            await this.fetchCaseQueriesData();
            await this.clickEvent(this.tree);
            this.isLoading = false;
            this.handleLinkClicked(null, this.currentTab == "prebuilt" ? (this.prebuiltData?.data?.length > 0 ? this.prebuiltData?.data : []) : this.caseResultData?.data?.length > 0 ? this.caseResultData?.data : [], this.currentTab);
        }
        this.getToolData();
        this.getCountriesList();
        this.getEntityList();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "search-engine-tool");
        clearInterval(this.intervalId);
        EventBus.$off("nlpDone");
        // document.removeEventListener('click', this.closeDropdown);

        // document
        //   .getElementById("case-list")
        //   .removeEventListener("scroll", this.onScroll);
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_BUILD_QUERIES", "SET_ALL_MONITORING_DATA", "SET_SELECTED_TOOL_QUERY"]),
        ...mapActions(["setAnalayzerOptions", "getAnalysisDropdownData", "getAllCaseUsernames", "getAllCaseEmails", "getAllCasePhones", "getAllCaseEducations", "getAllCaseEmployments", "getAllCaseHobbies", "getAllCaseLocations", "getAllCaseCompanies", "getAllCaseOrganisations", "getAllCaseDomains", "getQueryMonitoringEvents", "getNewMonitoringEvents"]),
        async fetchFilterData(filter, customTitles) {
            if (!filter.dataFetched && !customTitles.includes(filter.name)) {
                const url = "/ner-analyzer/global-filter-extended";
                let {data} = await axios.post(url, {case_id: this.$route.params.id, keyword_type: filter.name, page: filter.page, limit: filter.limit, tool: "search-engine", request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id});
                data.data.forEach((res) => {
                    res.checked = false;
                });
                filter.data = data.data;
                filter.dataFetched = true;
            }
            if (filter.name == "topicClassification" && !filter.dataFetched) {
                const ner_categories = await axios.get(`/case-data/${this.$route.params.id}/ner-categories?tool=search-engine-tool&query_id=${this.currentTab == "manual" ? this.manual_queryId : this.tree.id}`);
                if (ner_categories?.data) {
                    ner_categories?.data.forEach((res) => {
                        if (res.children) {
                            this.addChecked(res);
                        } else res.checked = false;
                    });
                    this.savedTopicData = ner_categories.data;
                    filter.data = ner_categories?.data;
                    filter.dataFetched = true;
                }
            }
            if (filter.name == "internetQueries" && !filter.dataFetched) {
                let reqBody = {case_id: this.case_id, run_status: this.run_status, done_status: this.doneFilterVal, visited_status: this.visitedFilterVal, adverse: this.params.adverse};
                const {data} = await axios.post("/search-engine/queryset/queries", reqBody);
                let queries = data.queries.map((res) => res.maskedQuery || res.search_query);
                let finalData = [];
                queries.forEach((res) => {
                    finalData.push({key: res.replaceAll('"', ""), checked: false, count: 1});
                });
                this.savedInternetQueries = finalData;
                filter.data = finalData;
                filter.dataFetched = true;
            }
        },
        addChecked(res) {
            res.checked = false;
            res.children.forEach((el) => {
                if (el.children != null) {
                    this.addChecked(el);
                } else {
                    el.checked = false;
                }
            });
        },
        async highlightItems() {
            if (NLPAnalysisCollection.isEmpty()) {
                this.$bvToast.toast("No media content on the page", {
                    title: "Highlighting warning",
                    variant: "warning",
                    autoHideDelay: 2000,
                    solid: true,
                });

                return;
            }

            try {
                this.analyzerOptions.forEach(async (el) => {
                    if (el.analysis && el.name === "Case Identifiers") await NLPAnalysisCollection.highlightCaseIdentifiers();
                    if (el.analysis && el.name === "Negative Words") await NLPAnalysisCollection.highlightNegativeWords(this.getBadWordsLanguages);
                    if (el.analysis && el.name === "Product Keywords") await NLPAnalysisCollection.highlightProductWords();
                });
            } catch {
                console.log(e);
            }
        },
        handleAutorun() {
            this.autorun = !this.autorun;
        },
        entitySelection() {
            this.handleClear();
        },
        submitBuiltQueries(queries) {
            this.saveBuildQueries(queries);
            this.runQueriesAnalyzer("submit");
            this.currentStep = 1;
            sessionStorage.setItem(
                "search-engine-steps",
                JSON.stringify({
                    currentStep: this.currentStep,
                    steps: this.steps,
                })
            );
        },

        searchQueriesSelected() {
            this.selectedSaveQueries = false;
            for (var i = 0; i < this.prebuiltQueryGen.children.length; i++) {
                let selected = this.prebuiltQueryGen.children[i]?.children?.some((obj) => obj.count > 0);
                if (selected) {
                    this.selectedSaveQueries = true;
                    break;
                }
            }
        },
        handleClearAnalysis() {
            this.analyzerOptions?.forEach((el) => (el.analysis = false));
            this.negativeWordLanguages = [];
            this.negativeLanguages = true;
            this.nlpCount = 0;
        },

        toggleAllCategories() {
            this.queriesSections = this.queriesSections.map((section) => ({
                ...section,
                expanded: !this.allCategoriesExpanded,
            }));
            this.allCategoriesExpanded = !this.allCategoriesExpanded;
        },

        handleBack() {
            this.steps[this.currentStep].visited = false;
            this.steps[this.currentStep].submitted = false;
            this.currentStep -= 1;
            this.steps[this.currentStep].submitted = false;
            if (this.currentStep === 1) {
                this.runQuery = false;
                if (this.$refs.searchEngine?.$children) {
                    this.handleExpandAll("searchEngine");
                }
            }
            sessionStorage.setItem(
                "search-engine-steps",
                JSON.stringify({
                    currentStep: this.currentStep,
                    steps: this.steps,
                })
            );
        },

        clearSelectedQueries() {
            this.selectvalue = true;
            this.prebuiltQueryGen.children.map((el) => {
                el.children.map((child) => {
                    if (child.children.length > 0) {
                        child.children.map((ch) => (ch.selected = false));
                        child.count = 0;
                    }
                    child.selected = false;
                });
                el.count = 0;
                el.selected = false;
            });
            this.selectvalue = false;
            this.selectedSaveQueries = false;
            sessionStorage.removeItem("search-engine-selected-queries");
        },

        permissionExists(key) {
            return this.getPermissions.find((res) => FEATURES.tools[res] == key);
        },

        handleNlpAnalysis(option) {
            this.analyzerOptions.forEach((el) => {
                if (el.name === option.name) {
                    el.analysis = !el.analysis;
                    if (el.analysis) {
                        this.nlpCount++;
                        if (el.name === "Negative Words") this.negativeLanguages = false;
                    } else {
                        if (el.name === "Negative Words") this.negativeLanguages = true;
                        this.nlpCount--;
                    }
                }
            });
            this.$forceUpdate();
        },
        creditPack() {
            this.creditinfo = true;
        },
        handleChildCheckbox(names, category, event) {
            this.selectvalue = true;
            this.handleChildChecked(names, category);
            event.stopPropagation();
            this.selectvalue = false;
        },
        handleChildChecked(name, category, flag) {
            // sessionStorage.removeItem("search-engine-selected-queries");
            this.selectvalue = true;
            let data = document.getElementById(name.text).checked;
            if (!data && data !== false) data = false;
            name.selected = flag ? !data : data;

            let count = 0;
            count = category.children.filter((el) => el.selected)?.length;
            category.count = count;
            document.getElementById(category.text).checked = count > 0;
            category.selected = count > 0;
            this.selectvalue = false;
            this.searchQueriesSelected();
        },
        handleParentChecked(category) {
            // sessionStorage.removeItem("search-engine-selected-queries");
            this.selectvalue = true;
            let data = document.getElementById(category.text).checked;
            if (!data && data !== false) data = false;
            category.selected = data;
            category.children.forEach((el) => {
                el.selected = data;
            });
            category.count = data ? category.children.length : 0;
            this.selectvalue = false;
            this.searchQueriesSelected();
        },
        handleParentClick(section, category) {
            const val = this.selectedCategory?.[section.label]?.[category.text] || false;
            this.selectedCategory = {};
            if (!this.selectedCategory[section.label]) {
                this.selectedCategory[section.label] = {};
                if (!this.selectedCategory[section.label][category.text]) this.selectedCategory[section.label][category.text] = false;
            }
            this.selectedCategory[section.label][category.text] = !val;
            this.searchQueriesSelected();
        },
        handleOutsideClick(event) {
            const dropdown = event.target.closest(".input.drop-item.tw-relative");

            if (dropdown) {
                const section = dropdown.id?.split("___")?.[0];
                const category = dropdown.id?.split("___")?.[1];
                const value = selected[section][category];
                let selected = {};

                if (!selected[section]) {
                    selected[section] = {};
                    if (!selected[section][category]) selected[section][category] = false;
                }
                selected[section][category] = !value;
                this.selectedCategory = selected;
            } else this.selectedCategory = {};
        },
        getSelectedCategory(category) {
            this.$nextTick(() => {
                let count = 0;
                category.children.forEach((el) => {
                    if (el.selected) {
                        count++;
                    }
                });
                return count;
            });
        },

        async saveBuildQueries(queries) {
            this.runQuery = false;
            this.steps[this.currentStep].submitted = true;
            this.currentStep += 1;
            this.steps[this.currentStep].visited = true;

            let data = [];
            this.searchEngineQueries = [];
            queries.forEach((el) => {
                el.children.map((cat) => {
                    cat.children?.map((child) => {
                        {
                            if (child.selected) {
                                this.searchEngineQueries.push(child.id);
                                data.push({query_id: child.id, selected: child.selected});
                            } else data.push({query_id: child.id, selected: false});
                        }
                    });
                });
            });
            let payload = {
                case_id: this.$route.params.id,
                auto_run: this.autorun,
                search_engine_queries: data,
            };
            let response = await axios.post("/search-engine/save-selected-queries", payload);
            if (response.status && this.creditinfo) {
                this.runQueriesAnalyzer("buildQueries");
            }
            this.steps[1].submitted = true;
            this.steps[1].visited = true;
            sessionStorage.setItem(
                "search-engine-steps",
                JSON.stringify({
                    currentStep: this.currentStep,
                    steps: this.steps,
                })
            );
            // this.queryGenerator = this.prebuiltQueryGen;
            sessionStorage.setItem("search-engine-selected-queries", JSON.stringify({prebuildQueries: this.prebuiltQueryGen, selectedSaveQueries: this.selectedSaveQueries, autorun: this.autorun}));
            this.handleClearAnalysis();
        },

        // async getSearchengineLanguages() {
        //     await axios
        //         .get(`/search-engine/languages?current_page=1&limit=100&product_id=${this.getProduct.key}`)
        //         .then((response) => {
        //             if (response && response.data) {
        //                 const language = response.data.data.map((el) => ({
        //                     value: el.language,
        //                 }));
        //                 this.languages = [
        //                     {
        //                         group: "Languages",
        //                         categories: language,
        //                     },
        //                 ];
        //             }
        //         })
        //         .catch((error) => {});
        // },
        async runQueriesAnalyzer() {
            // this.queryGenerator = {};
            this.queryGenerator = JSON.parse(JSON.stringify(this.prebuiltQueryGen));
            this.saveSelectedQueries = true;
            this.runQuery = true;
            this.prebuiltData = {};
            this.resultsCount = null;
            this.analyzerCount = 0;
            this.loadMore = false;
            this.expanded = !this.expanded;
            this.analysisStatusAbort();
            let payload = {};
            this.analyzerOptions?.forEach((el) => {
                payload[el.payload_name] = el.analysis;
            });
            payload.languages = this.negativeWordLanguages.length > 0 ? this.negativeWordLanguages?.map((elem) => elem.value) : [];
            this.steps[this.currentStep].submitted = true;
            let queries = await axios.post("/search-engine/run-queries-analyzer", {
                case_id: this.$route.params.id,
                search_engine_queries: this.searchEngineQueries,
                config: payload,
            });
            if (queries.data.status) {
                this.autoAnalyzerId = queries.data.auto_run_status_id;
                sessionStorage.setItem("search-engine-build-queries", JSON.stringify({selectedQueries: this.selectedPrebuiltQueryGen, runQuery: this.runQuery, buildQueries: this.buildQueries, analyzerOptions: this.analyzerOptions, nlpCount: this.nlpCount, autoAnalyzerId: this.autoAnalyzerId}));
                sessionStorage.setItem(
                    "search-engine-steps",
                    JSON.stringify({
                        currentStep: this.currentStep,
                        steps: this.steps,
                    })
                );
                if (this.autoAnalyzerId) {
                    await this.autorunstatus;
                }
                this.expandSection = false;
                this.setAnalayzerOptions(this.analyzerOptions);
                setTimeout(() => {
                    if (this.$refs.searchEngine?.$children) {
                        this.handleExpandAll("searchEngine");
                    }
                }, 1500);
            }
        },

        handleInternetQuerySearch(filter) {
            let arr = [];
            if (filter.data.length === 0) filter.data = this.savedInternetQueries;
            let regex = new RegExp(filter.searchedTerm);
            for (const item of filter.data) {
                if (regex.test(item.key)) {
                    arr.push(item);
                }
            }
            if (filter.searchedTerm) {
                filter.data = arr;
            } else {
                filter.data = this.savedInternetQueries;
            }
        },
        filterInternetQueries(items, query) {
            let arr = [];
            let regex = new RegExp(query);
            for (const item of items.data) {
                if (regex.test(item.key)) {
                    arr.push(item);
                }
            }
        },
        filterItemsRecursively(items, query, parent) {
            let arr = [];
            for (const item of items) {
                if (this.caseInsensitiveSearch(item.category, query)) {
                    if (item.level == 1) arr.push(item);
                    else arr.push(parent);
                } else {
                    if (item?.children?.length) {
                        arr.push(...this.filterItemsRecursively(item.children, query, parent || item));
                    }
                }
            }
            return arr;
        },
        caseInsensitiveSearch(targetString, searchString) {
            const regex = new RegExp(searchString, "i");
            return regex.test(targetString);
        },
        handleTopicSearch(filter) {
            // filter.data = filter.data.filter(item => this.caseInsensitiveSearch(item.key, filter.searchedTerm))
            if (filter.data.length === 0) filter.data = this.savedTopicData;
            let searchResults = this.filterItemsRecursively(filter.data, filter.searchedTerm);
            filter.data = filter.searchedTerm ? searchResults : this.savedTopicData;
        },

        async handleMoreData(filter) {
            filter.dataFetched = false;
            const url = "/ner-analyzer/global-filter-extended";
            let {data} = await axios.post(url, {case_id: this.$route.params.id, keyword_type: filter.name, page: filter.page, limit: filter.limit, search_string: filter.searchedTerm, tool: "search-engine", request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id});
            data.data.forEach((res) => {
                res.checked = false;
            });
            filter.data.push(...data.data);
            if (data.data.length > 0) {
                filter.selectAll = false;
            }
            filter.dataFetched = true;
        },
        handleSearchboxChange: debounce(async function(filter, caseId) {
            if (filter.name != "internetQueries") {
                if (!filter.searchedTerm) {
                    filter.page = 1;
                    filter.limit = 6;
                }
                filter.dataFetched = false;
                const url = "/ner-analyzer/global-filter-extended";
                let {data} = await axios.post(url, {case_id: caseId, keyword_type: filter.name, page: filter.page, limit: filter.limit, search_string: filter.searchedTerm, tool: "search-engine", request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id});
                data.data.forEach((res) => {
                    res.checked = false;
                });
                filter.data = data.data;
                filter.dataFetched = true;
            }
        }, 1000),
        getOptionsList(list) {
            return [
                {
                    group: list === "companyUsers" ? "Company Name" : list === "hobbyList" ? "Hobbies" : list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        getCategory(category) {
            let data = category.children?.map((el) => {
                return {label: el.text, id: el.id, value: el.text};
            });
            return [
                {
                    group: category.text,
                    categories: data,
                },
            ];
        },

        async getEntityList() {
            let data = await axios.get(`/search-engine/case-entity/${this.$route.params.id}`).then((response) => {
                return response.data.data;
            });
            this.entitiesList = data;
        },

        async getCountriesList() {
            let countries = [];
            await osintAxios
                .get("/search-engine/location")
                .then((response) => {
                    if (response && response.data) {
                        const country = response.data.data.map((el) => ({
                            label: el.country,
                            value: el.country_code,
                            option: el.country_cr_code,
                        }));
                        this.countriesOptions = [
                            {
                                group: "Countries",
                                categories: country,
                            },
                        ];
                    }
                })
                .catch((error) => {});
        },

        showAnalyseModal() {
            this.checkedQueries = this.$refs.case_query.selectedSearchQueries;
            const already_checked = this.caseQueries?.children?.flatMap((query) => query.children)?.some((query) => query.checkedValue);
            const checkedValues = this.checkedQueries?.some((query) => query.checkedValue);
            if (checkedValues || already_checked) {
                this.$modal.show("analyse-modal");
                this.totalqueries = this.caseQueriesData.queries;
            } else {
                this.$toast.error("Please select queries before analyse");
            }
        },

        updateFrequency(data) {
            this.$emit("updateFrequency", data);
        },
        updateMonitoring(data) {
            this.$emit("updateMonitoring", data);
        },

        moveToManualQueries(query) {
            this.expandSection = true;
            this.currentTab = "manual";
            this.searchKeyword = query;
            this.resultData = [];
            this.displayQuery.manual = "";
            this.displayQuery.case_query = "";
        },

        showModal(modalname) {
            //    EventBus.$emit('cheatSheet',modalname);
            this.$modal.show(modalname);
        },

        showPreview(data) {
            this.previewItem = data;
            osintAxios.post("/search-engine/preview", {item: data._id, case_id: data.case_id}).then((response) => {
                if (response.data.status) {
                    this.showPreviewModal = true;
                    let yourWindow = window.open();
                    yourWindow.opener = null;
                    yourWindow.location = response.data.preview;
                    yourWindow.target = "_blank";
                } else {
                    this.$toast.error(response.data.message);
                }
            });
        },

        async getToolData() {
            this.organisations = [];
            this.phoneList = await this.getDropdownData("phone");
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
            this.educationList = await this.getDropdownData("education");
            this.employmentList = await this.getDropdownData("employment");
            this.hobbyList = await this.getDropdownData("hobby");
            this.locationList = await this.getDropdownData("location");
            this.domainList = await this.getDropdownData("domain");
            this.websiteList = await this.getDropdownData("website");
            this.companyUsers = await this.getDropdownData("company_name");
            this.organisationUsers = this.getCaseOrganisations;
            if (this.organisationUsers.length > 0) {
                const organisationList = this.organisationUsers.filter((page1) => !this.companyUsers.some((page2) => page1.value === page2.value));
                this.companyUsers = [...this.companyUsers, ...organisationList];
            } //combining both companyname and organization name list:wq
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        handleChecked(data) {
            let allChecked = false;
            let allUsers = [...this.usernameList, ...this.phoneList, ...this.emailList, ...this.locationList, ...this.employmentList, ...this.educationList, ...this.hobbyList, ...this.domainList, ...this.companyUsers];
            this.$emit("updateToolData", "search-engine", allUsers, data);
            allChecked = allUsers?.length ? (allUsers.findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        async prebuitQueries(input) {
            if (input === "Entity Information") {
                await this.runInputs();
            }
        },

        async runInputs() {
            this.clickedLinks = [];
            this.runQuery = false;
            this.inputs = true;
            this.saveSelectedQueries = false;
            // this.selectedSaveQueries = true
            this.autorun = false;
            this.queryGenerator = {};
            // sessionStorage.removeItem("search-engine-selected-queries")
            this.SET_BUILD_QUERIES(true);
            // this.queriesSections = [];
            // this.prebuiltQueryGen.children.forEach((el) => {
            //     if (el.children.length > 0) this.queriesSections.push({label: el.text, expanded: true});
            // });
            let obj = JSON.parse(sessionStorage.getItem("search-engine-inputs"));
            sessionStorage.setItem(
                "search-engine-inputs",
                JSON.stringify({
                    fNameInput: this.fNameInput,
                    mNameInput: this.mNameInput,
                    lNameInput: this.lNameInput,
                    nickNameInput: this.nickNameInput,
                    fullNameInput: this.fullNameInput,
                    locationInput: this.locationInput,
                    educationInput: this.educationInput,
                    employmentInput: this.employmentInput,
                    rolesInput: this.rolesInput,
                    hobbiesInput: this.hobbiesInput,
                    emailInput: this.emailInput,
                    phoneInput: this.phoneInput,
                    usernameInput: this.usernameInput,
                    companyInput: this.selectedItem.company,
                    languageInput: this.selectedLanguageFilter.length > 0 ? this.selectedLanguageFilter : [{value: "English"}],
                    selectedCountryFilter: this.selectedCountryFilter,
                    companyCountryFilter: this.companyCountryFilter,
                    websiteInput: this.websiteInput,
                    companyLocationInput: this.companyLocationInput,
                    domainInput: this.domainInput,
                    companyphoneInput: this.companyphoneInput,
                    companyemailInput: this.companyemailInput,
                    companyLanguageInput: this.companyLanguageInput,
                    case_id: this.case_id,
                    selectedEntity: this.selectedEntity,
                    selectedEntityId: this.selectedEntityId,
                })
            );
            sessionStorage.setItem(
                "steps",
                JSON.stringify({
                    currentStep: this.currentStep,
                    steps: this.steps,
                })
            );
            this.isQueryLoading = true;
            let requestBody = {};
            if (this.selectedEntity === "person") {
                requestBody = {
                    first_name: this.fNameInput,
                    last_name: this.lNameInput,
                    middle_name: this.mNameInput,
                    nick_name: this.nickNameInput,
                    full_name: this.fullNameInput,
                    email: this.emailInput,
                    phone: this.phoneInput,
                    username: this.usernameInput,
                    location: this.selectedItem.location.length > 0 ? this.selectedItem.location.map((elem) => elem.value) : [],
                    country_code: this.selectedCountryFilter ? this.selectedCountryFilter.value : "",
                    country_cr_code: this.selectedCountryFilter?.option ? this.selectedCountryFilter.option : "",
                    education: this.selectedItem.education.length > 0 ? this.selectedItem.education?.map((elem) => elem.value) : [],
                    employment: this.selectedItem.employment.map((elem) => elem.value),
                    roles: this.rolesInput.map((elem) => elem.value),
                    hobbies: this.selectedItem.hobby?.map((elem) => elem.value),
                    selected_language: this.selectedLanguageFilter.length > 0 ? this.selectedLanguageFilter.map((elem) => elem.value) : "English",
                    product_id: this.getProduct.key,
                    entity_id: this.selectedEntityId?._id ? this.selectedEntityId?._id : null,
                };
            } else if (this.selectedEntity === "company") {
                requestBody = {
                    company: this.selectedItem.company?.map((elem) => elem.value),
                    domain: this.domainInput,
                    email: this.companyemailInput,
                    phone: this.companyphoneInput,
                    location: this.selectedCompanyItem.location.length > 0 ? this.selectedCompanyItem.location.map((elem) => elem.value) : [],
                    product_id: this.getProduct.key,
                    entity_id: this.selectedEntityId?._id ? this.selectedEntityId?._id : null,
                    website: this.website,
                    country_code: this.companyCountryFilter ? this.companyCountryFilter.value : "",
                    country_cr_code: this.companyCountryFilter?.option ? this.companyCountryFilter.option : "",
                    selected_language: this.companyLanguageFilter.length > 0 ? this.companyLanguageFilter.map((elem) => elem.value) : "English",
                };
            }
            const {data} = await axios.post("/search-engine/queries", requestBody);
            let queries = data.data;
            Object.keys(this.queriesObj).forEach((section) => {
                let data = queries.filter((query) => query.category === section);
                this.queriesObj[section] = data;
            });
            this.displayQuery = {
                prebuilt: "",
                manual: "",
                case_query: "",
            };
            this.prebuiltData = {};
            this.resultData = {};
            this.caseResultData = {};
            this.$refs.searchEngine?.$children?.[0]?.$children.forEach((ele) => {
                ele.expanded = this.expanded;
                if (ele.$children.length > 0) {
                    ele.$children.forEach((elem) => {
                        elem.expanded = this.expanded;
                    });
                }
                [...ele.$el.getElementsByClassName("panel expandible panel-transition")]?.forEach((e) => (e.style.maxHeight = "0"));
            });
            this.isQueryLoading = false;
            this.$forceUpdate();
            if (this.selectedItem?.email) this.setSearchedUser(this.selectedItem?.email.value, "email");
            if (this.selectedItem?.username) this.setSearchedUser(this.selectedItem?.username.value, "username");
            if (this.selectedItem?.phone) this.setSearchedUser(this.selectedItem?.phone.value, "phone");
            if (this.selectedCompanyItem?.domain) this.setSearchedUser(this.selectedCompanyItem?.domain.value, "domain");
            // if (this.selectedItem?.company) this.setSearchedUser(this.selectedItem?.company.value, "company_name");
            if (this.selectedItem?.company?.length) {
                this.selectedItem.company.forEach((company_name) => {
                    this.setSearchedUser(company_name.value, "company_name");
                });
            }
            if (this.selectedCompanyItem?.email) this.setSearchedUser(this.selectedCompanyItem?.email.value, "email");
            if (this.selectedCompanyItem?.phone) this.setSearchedUser(this.selectedCompanyItem?.phone.value, "phone");
            if (this.selectedCompanyItem?.location?.length) {
                this.selectedCompanyItem.location.forEach((location) => {
                    this.setSearchedUser(location.value, "location");
                });
            }
            if (this.selectedItem?.location?.length) {
                this.selectedItem.location.forEach((location) => {
                    this.setSearchedUser(location.value, "location");
                });
            }
            if (this.selectedItem?.employment?.length) {
                this.selectedItem.employment.forEach((employment) => {
                    this.setSearchedUser(employment.value, "employment");
                });
            }
            if (this.selectedItem?.education?.length) {
                this.selectedItem.education.forEach((education) => {
                    this.setSearchedUser(education.value, "education");
                });
            }
            if (this.selectedItem?.hobby?.length) {
                this.selectedItem.hobby.forEach((hobby) => {
                    this.setSearchedUser(hobby.value, "hobby");
                });
            }
            this.steps[0].submitted = true;
            this.steps[1].visited = true;
            this.currentStep = 1;
            this.searchQueriesSelected();
        },

        limitText(length) {
            // this.$emit('limitText', length);
            return ` +${length}`;
        },

        setInput(input, field) {
            this[input] = this.selectedItem[field]?.value && this.selectedItem[field].value !== {} ? this.selectedItem[field].value : null;
        },

        setCompanyDetailsInput(input, field) {
            this[input] = this.selectedCompanyItem[field]?.value && this.selectedCompanyItem[field].value !== {} ? this.selectedCompanyItem[field].value : null;
        },

        setCompanyEventInput(option) {
            this.companyEventInput.push(option);
        },

        setLocationInput(option) {
            this.locationInput.push(option);
            // this.setSearchedUser(option.value, "location");
        },

        setCompanyLocationInput(option) {
            this.companyLocationInput.push(option);
        },

        setEmploymentInput(option) {
            this.employmentInput.push(option);
            // this.setSearchedUser(option.value, "employment");
        },

        setOtherNameInput(option) {
            this.otherNameInput.push(option);
        },

        setOtherCompanyNameInput(option) {
            this.otherCompanyNameInput.push(option);
        },

        setEducationInput(option) {
            this.educationInput.push(option);
            // this.setSearchedUser(option.value, "education");
        },

        setHobbyInput(option) {
            this.hobbiesInput.push(option);
            // this.setSearchedUser(option.value, "hobby");
        },
        setCompanyInput(option) {
            if (typeof this.companyInput === "string") {
                let company = [{value: this.companyInput}];
                this.companyInput = company;
            }
            this.companyInput.push(option);
        },
        setEventInput(option) {
            this.eventInput.push(option);
        },
        setLanguageInput(option) {
            this.languageInput.push(option);
        },
        setTheCustomEmail(value) {
            this.emailInput = value;
            this.selectedItem.email = {
                value: value,
            };
        },
        setTheCustomPhone(value) {
            this.phoneInput = value;
            this.selectedItem.phone = {
                value: value,
            };
        },
        setCompanyCustomPhone(value) {
            this.companyphoneInput = value;
            this.selectedCompanyItem.phone = {
                value: value,
            };
        },
        setTheCustomUsername(value) {
            this.usernameInput = value;
            this.selectedItem.username = {
                value: value,
            };
        },
        setCompanyCustomEmail(value) {
            this.companyemailInput = value;
            this.selectedCompanyItem.email = {
                value: value,
            };
        },
        // setTheCustomCompany(value) {
        //     this.companyInput = value;
        //     this.selectedItem.company = {
        //         value: value,
        //     };
        // },
        setTheCustomDomain(value) {
            this.domainInput = value;
            this.selectedCompanyItem.domain = {
                value: value,
            };
        },
        setTheCustomWebsite(value) {
            this.websiteInput = value;
            this.selectedCompanyItem.website = {
                value: value,
            };
        },

        addRolesTag(newTag) {
            const tag = this.getTag(newTag);
            this.rolesInput.push(tag);
        },
        addCompanyTag(newTag) {
            if (typeof this.companyInput === "string") {
                let company = [{value: this.companyInput}];
                this.companyInput = company;
            }
            if (typeof this.selectedItem["company"] === "string") {
                let company = [{value: this.companyInput}];
                this.selectedItem["company"] = company;
            } else if (typeof this.selectedItem["company"] === "object") {
                let companyselected;
                if (Object.keys(this.selectedItem["company"]).length === 1) {
                    companyselected = Array.isArray(this.selectedItem["company"]) ? this.selectedItem["company"] : [this.selectedItem["company"]];
                    this.selectedItem["company"] = companyselected;
                }
            }
            const tag = this.getTag(newTag);
            this.companyInput.push(tag);
            this.selectedItem["company"].push(tag);
        },

        addLocationTag(newTag) {
            const tag = this.getTag(newTag);
            this.locationInput.push(tag);
            this.selectedItem["location"].push(tag);
            // this.setSearchedUser(newTag, "location");
        },

        addCompanyLocationTag(newTag) {
            const tag = this.getTag(newTag);
            this.companyLocationInput.push(tag);
            this.selectedCompanyItem["location"].push(tag);
        },

        addOtherNameTag(newTag) {
            const tag = this.getTag(newTag);
            this.otherNameInput.push(tag);
            this.selectedItem["otherName"];
        },
        addOtherCompanyNameTag(newTag) {
            const tag = this.getTag(newTag);
            this.otherCompanyNameInput.push(tag);
            this.selectedCompanyItem["otherCompanyName"];
        },
        addEducationTag(newTag) {
            const tag = this.getTag(newTag);
            this.educationInput.push(tag);
            this.selectedItem["education"].push(tag);
            // this.setSearchedUser(newTag, "education");
        },

        addEmploymentTag(newTag) {
            const tag = this.getTag(newTag);
            this.employmentInput.push(tag);
            this.selectedItem["employment"].push(tag);
            // this.setSearchedUser(newTag, "employment");
        },
        addHobbyTag(newTag) {
            const tag = this.getTag(newTag);
            this.hobbiesInput.push(tag);
            this.selectedItem["hobby"].push(tag);
            // this.setSearchedUser(newTag, "hobby");
        },
        addEventTag(newTag) {
            const tag = this.getTag(newTag);
            this.eventInput.push(tag);
            this.selectedItem["events"].push(tag);
        },
        addCompanyEventTag(newTag) {
            const tag = this.getTag(newTag);
            this.companyEventInput.push(tag);
            this.selectedCompanyItem["events"].push(tag);
        },
        removeOtherCompanyNameTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.otherCompanyNameInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.otherCompanyNameInput.splice(match, 1);
            }
        },
        removeCompanyLocationTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.companyLocationInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.companyLocationInput.splice(match, 1);
            }
        },
        removeCompanyEventTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.companyEventInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.companyEventInput.splice(match, 1);
            }
        },
        removeOtherNameTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.otherNameInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.otherNameInput.splice(match, 1);
            }
        },
        removeCompanyOtherNameTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.otherCompanyNameInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.otherCompanyNameInput.splice(match, 1);
            }
        },
        removeEventTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.eventInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.eventInput.splice(match, 1);
            }
        },
        removeLanguageTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.languageInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.languageInput.splice(match, 1);
            }
        },

        removeLocationTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.locationInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.locationInput.splice(match, 1);
                // this.selectedItem["location"].splice(match, 1);
            }
        },
        removeEmploymentTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.employmentInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.employmentInput.splice(match, 1);
                // this.selectedItem["employment"].splice(match, 1);
            }
        },
        removeEducationTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.educationInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.educationInput.splice(match, 1);
                // this.selectedItem["education"].splice(match, 1);
            }
        },
        removeCompanyTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.companyInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.companyInput.splice(match, 1);
                // this.selectedItem["education"].splice(match, 1);
            }
        },
        removeHobbyTag(removedOption) {
            const tag = removedOption;
            let match = -1;
            match = this.hobbiesInput.findIndex((item) => {
                return item.value === removedOption.value;
            });
            if (match > -1) {
                this.hobbiesInput.splice(match, 1);
                // this.selectedItem["hobby"].splice(match, 1);
            }
        },

        async setSearchedUser(searchKeyword, type, val_type) {
            const data = {
                value: searchKeyword,
                platform: "search-engine",
                val_type: val_type || type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);

            let listName = type !== "company_name" ? `${type}List` : "companyUsers";
            // if (isSuccess) {
            let dataItem = this[listName]?.find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[listName].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        getSelectedEntity(event, data) {
            this.tabChanged = false;
            if (this.currentTab === "prebuilt") {
                this.runQuery = false;
                this.expandSection = true;
                if (event === "clear") {
                    this.currentStep = 0;
                    this.steps = [
                        {value: 1, label: "Entity Information", visited: true, submitted: false},
                        {value: 2, label: "Queries", visited: false, submitted: false},
                        {value: 3, label: "Analysis", visited: false, submitted: false},
                    ];
                    this.handleClear();
                } else {
                    this.selectedEntity = event.entity_type;
                    this.selectedEntityId = data;
                    this.entity = event;
                    this.currentStep = 0;
                    this.steps = [
                        {value: 1, label: "Entity Information", visited: true, submitted: false},
                        {value: 2, label: "Queries", visited: false, submitted: false},
                        {value: 3, label: "Analysis", visited: false, submitted: false},
                    ];
                    if (event.entity_type === "person") {
                        this.fNameInput = event.first_name;
                        this.mNameInput = event.middle_name;
                        this.lNameInput = event.last_name;
                        this.nickNameInput = event.nick_name;
                        this.fullNameInput = event.full_name;
                        this.selectedItem.email = event.email.length > 0 ? {value: event.email[0]} : "";
                        if (event.location.length > 0) event.location.map((el) => this.selectedItem.location.push({value: el}));
                        this.locationInput = this.selectedItem.location.length > 0 ? this.selectedItem.location : [];
                        this.selectedItem.other_name = event.other_name.length > 0 ? {value: event.other_name[0]} : "";
                        this.selectedItem.phone = event.phone.length > 0 ? {value: event.phone[0]} : "";
                        this.phoneInput = this.selectedItem.phone.value ? this.selectedItem.phone.value : "";
                        this.selectedItem.roles = event.roles.length > 0 ? {value: event.roles[0]} : [];
                        this.selectedItem.username = event.username.length > 0 ? {value: event.username[0]} : [];
                        this.usernameInput = this.selectedItem.username.value;
                        if (event.education.length > 0) {
                            event.education.map((el) => this.selectedItem.education.push({value: el}));
                        }
                        this.educationInput = this.selectedItem.education.length > 0 ? this.selectedItem.education : [];
                        if (event.employment.length > 0) {
                            event.employment.map((el) => this.selectedItem.employment.push({value: el}));
                        }
                        this.employmentInput = this.selectedItem.employment.length > 0 ? this.selectedItem.employment : [];
                        if (event.hobbies.length > 0) {
                            event.hobbies.map((el) => this.selectedItem.hobby.push({value: el}));
                        }
                        this.hobbiesInput = this.selectedItem.hobby.length > 0 ? this.selectedItem.hobby : [];
                        this.emailInput = this.selectedItem.email.value ? this.selectedItem.email.value : "";
                        this.selectVal["email"] = this.selectedItem.email.value ? this.selectedItem.email.value : "";
                        this.selectVal["phone"] = this.selectedItem.phone.value ? this.selectedItem.phone.value : "";
                        this.selectVal["username"] = this.selectedItem.username.value ? this.selectedItem.username.value : "";
                    }
                    if (event.entity_type === "company") {
                        // sessionStorage.removeItem("search-engine-inputs");
                        this.selectedItem.company = [{value: event.company_name}];
                        this.companyInput = this.selectedItem.company;
                        this.selectedCompanyItem.email = event.email.length > 0 ? {value: event.email[0]} : "";
                        this.selectedCompanyItem.location = event.location.length > 0 ? [{value: event.location[0]}] : [];
                        this.companyLocationInput = this.selectedCompanyItem.location.length > 0 ? this.selectedCompanyItem.location : [];
                        this.selectedCompanyItem.phone = event.phone.length > 0 ? {value: event.phone[0]} : "";
                        this.companyphoneInput = this.selectedCompanyItem.phone.value ? this.selectedCompanyItem.phone.value : "";
                        this.companyemailInput = this.selectedCompanyItem.email.value ? this.selectedCompanyItem.email.value : "";
                        this.selectedCompanyItem.website = event.website.length > 0 ? {value: event.website[0]} : {value: event.website};
                        this.selectedCompanyItem.domain = event.domain.length > 0 ? {value: event.domain[0]} : "";
                        this.domainInput = this.selectedCompanyItem.domain.value;
                        this.websiteInput = this.selectedCompanyItem.website.value;
                        this.selectCompanyVal["email"] = this.selectedCompanyItem.email.value ? this.selectedCompanyItem.email.value : "";
                        this.selectCompanyVal["phone"] = this.selectedCompanyItem.phone.value ? this.selectedCompanyItem.phone.value : "";
                    }
                }
                sessionStorage.setItem(
                    "search-engine-steps",
                    JSON.stringify({
                        currentStep: 0,
                        steps: this.steps,
                    })
                );
            }
            if (this.currentTab === "case_query") {
                this.caseEntityId = [];
                this.caseEntityId = data;
                this.fetchCaseQueriesData();
            }
        },

        async handleSearch() {
            if (this.searchKeyword) {
                this.searchAllNext = {
                    query: this.searchKeyword,
                    value: 1,
                };
                this.resultData = [];
                if (!this.showResultCount) this.showResultCount = {};
                this.showResultCount[this.currentTab] = false;
                this.isLoading = true;
                this.displayQuery.manual = this.searchKeyword;
                let search_keyword = this.searchKeyword;
                let matches = /.*?(\{(.+?) (.+?)\}).*?/.exec(this.searchKeyword);
                if (matches && matches.length == 4) {
                    const {data} = await axios.post("/search-engine/manual-query", {search_keyword, matches});
                    search_keyword = data.search_keyword;
                }
                this.manualSearchQuery = search_keyword;
                let payload = {query: search_keyword, label: this.searchKeyword};
                if (this.selectedEntityId) payload.entity_id = this.selectedEntityId._id;
                const manualData = await axios.post("/search-engine/generated-query", payload);
                if (manualData.data && manualData.data._id) {
                    this.manual_queryId = manualData.data._id;
                }
                let bodyData = {};
                if (this.currentTab == "manual") {
                    bodyData = {
                        query: search_keyword,
                        request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                    };
                } else {
                    bodyData = {
                        query: search_keyword,
                        country_code: this.selectedCountryFilter ? this.selectedCountryFilter.value : "",
                        country_cr_code: this.selectedCountryFilter?.option ? this.selectedCountryFilter.option : "",
                        request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                    };
                }
                this.$emit("searched", {data: {query: this.searchKeyword}, source: "search-engine"});
                const {data} = await osintAxios.post("/search-engine", bodyData, {headers: {"x-tool-name": "search-engine-tool"}});
                if (data?.data && data.data.length > 0) {
                    // this.resultData = data;
                    this.resultData.next = data.next;
                    // this.$emit("searchResult", { data: resultData.data, source: "search-engine", searchType: "query" });
                    await this.sendDataToDB(data, true);
                } else {
                    await this.sendDataToDB(data, true);
                    this.$toast.warning("Initial Scan had no results. Please wait performing deep scan...");
                }
                if (!this.resultsCount) this.resultsCount = {};
                this.resultsCount[this.currentTab] = data?.total_results;
                if (data?.warning) this.$toast.warning(data.warning);
                // this.resultData = data;
                this.isLoading = false;
                this.page_number = 1;
                await this.addSerpData(false, true);
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async onScroll() {
            let header = document.getElementById("case-list");
            if (header.scrollTop > header.scrollHeight - header.offsetHeight - 400) {
                if (!this.lazyLoading) {
                    await this.getSearchEngineData();
                }
            }
        },
        async getSearchEngineData() {
            this.lazyLoading = true;
            this.isLoading = true;
            if (this.currentTab == "prebuilt") {
                if (this.prebuiltData?.next) {
                    const {data} = await osintAxios.post("/search-engine", {
                        query: this.prebuiltQuery,
                        start: this.prebuiltData?.next,
                        country_code: this.selectedCountryFilter ? this.selectedCountryFilter.value : "",
                        country_cr_code: this.selectedCountryFilter?.option ? this.selectedCountryFilter.option : "",
                    });
                    if (data?.data?.length > 0) {
                        // this.prebuiltData.data = this.prebuiltData.data?.concat(data?.data);
                        this.prebuiltData.next = data?.next;
                        await this.sendDataToDB(data, false);
                        this.lazyLoading = false;
                    } else {
                        this.lazyLoading = true;
                    }
                    if (data?.warning) this.$toast.warning(data.warning);
                } else {
                    this.lazyLoading = false;
                }
            } else if (this.currentTab == "manual") {
                if (this.resultData?.next) {
                    const {data} = await osintAxios.post("/search-engine", {
                        query: this.searchKeyword,
                        start: this.resultData.next,
                    });
                    if (data?.data?.length > 0) {
                        // this.resultData.data = this.resultData.data?.concat(data.data);
                        this.resultData.next = data?.next;
                        await this.sendDataToDB(data, true);
                        this.lazyLoading = false;
                    } else {
                        this.lazyLoading = true;
                    }
                    if (data?.warning) this.$toast.warning(data.warning);
                } else {
                    this.lazyLoading = false;
                }
            } else {
                if (this.caseQuery.page == 1 || this.caseResultData?.total_page > this.caseQuery.page) {
                    this.caseQuery.page += 1;
                    const {data} = await axios.post("/datapoints", this.caseQuery);
                    if (data?.data?.length > 0) {
                        this.caseResultData.data = this.caseResultData.data?.concat(data.data);
                        // if (this.tabName === "tools") {
                        //     this.SET_ALL_TOOLS_DATA({
                        //         ...this._data,
                        //         "tool-name": "search-engine-tool",
                        //     });
                        // } else {
                        //     this.SET_ALL_MONITORING_DATA({
                        //         ...this._data,
                        //         "tool-name": "search-engine-tool",
                        //     });
                        // }
                        this.lazyLoading = false;
                        // if (this.caseResultData?.data) {
                        //   this.handleLinkClicked(
                        //     null,
                        //     this.caseResultData.data,
                        //     "case_query"
                        //   );
                        // }
                    } else {
                        this.lazyLoading = true;
                    }
                }
            }
            if ((this.prebuiltData?.data && this.prebuiltData?.data.length > 0) || (this.caseResultData?.data && this.caseResultData?.data.length > 0)) {
                // this.handleLinkClicked(null, this.currentTab == "prebuilt" ? this.prebuiltData?.data : this.caseResultData?.data, this.currentTab);
                if (this.currentTab !== "manual") {
                    this.handleLinkClicked(null, this.currentTab == "prebuilt" ? this.prebuiltData?.data : this.caseResultData?.data, this.currentTab);
                }
            }
            this.isLoading = false;
        },

        async fetchSerpData(query, searchData, manual, filters = false, totalCount = false) {
            if (!this.searchAllNext.value) return;
            this.isLoading = true;
            let response;
            if (!this.autorun || this.loadMore || this.currentTab === "manual") {
                response = await osintAxios.post(
                    "/search-engine/all",
                    {
                        query: query,
                        start: this.currentTab === "prebuilt" || this.currentTab === "manual" ? this.searchAllNext.value : 1,
                        country_code: this.currentTab === "prebuilt" ? (this.selectedCountryFilter ? this.selectedCountryFilter.value : "") : "",
                        request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                    },
                    {headers: {"x-query-id": this.tree.id}}
                );
            }

            if (this.currentTab === "prebuilt" || this.currentTab === "manual") {
                this.searchAllNext = {
                    query,
                    value: this.autorun && !this.loadMore && this.currentTab !== "manual" ? searchData.pagination_info.next : response.data.next,
                };
            }
            if (totalCount) {
                if (!this.autorun) {
                    if (!this.resultsCount) this.resultsCount = {};
                    this.resultsCount[this.currentTab] += response.data?.total_results;
                }
                if (!this.showResultCount) this.showResultCount = {};
                this.showResultCount[this.currentTab] = true;
            }
            if (filters) {
                const filteredData = await this.getFilteredData(false);
                this.prebuiltData = {data: filteredData?.data, next: 2};
                this.getTotalPagesFilter = filteredData?.pagination_info.total_pages;
                this.fetchedFilteredPages.push(1);
                this.fetchedFilteredPages = [];
            } else {
                this.fetchedFilteredPages = [];
                if (!this.autorun || this.loadMore) {
                    const {data} = await this.saveSearchData(response.data, manual);
                    if (data && data.data.length > 0) {
                        this.$emit("searchResult", {data: data?.data, source: "search-engine", searchType: "query"});
                        if (searchData) {
                            data.data.forEach((serpItem) => {
                                const searchObj = searchData?.find((searchItem) => searchItem.link === serpItem.link);
                                if (!searchObj) {
                                    searchData.push(serpItem);
                                }
                            });
                            if (this.prebuiltData.data && this.currentTab == "prebuilt") {
                                this.prebuiltData.data = searchData;
                            } else this.resultData.data = searchData;
                        } else {
                            if (this.currentTab == "prebuilt") {
                                this.prebuiltData = {data: data.data, next: 1};
                            } else {
                                this.resultData = {data: data.data, next: 1};
                            }
                        }
                        if (this.deepScanManual) {
                            if (!this.resultData.data) this.resultData.data = [];
                            this.resultData.data = [...this.resultData.data, ...data.data];
                            if (!this.resultData.data) this.isLoading = false;
                        }
                        if (!response.data?.next) {
                            this.deepScanManual = false;
                            this.deepScanManualDone = true;
                        }
                    } else if (!this.searchedData?.length) {
                        this.$toast.error("No Results Found");
                    }
                } else if (this.currentTab == "prebuilt") {
                    this.prebuiltData = {data: searchData.data, next: 1};

                    // this.autorun = false;
                } else if (!this.searchedData?.length) {
                    this.$toast.error("No Results Found");
                }
            }
            // if (this.currentTab == "prebuilt") {
            //     this.prebuiltData.data.forEach((res) => {
            //         if (this.getAutoAnalyze && res.analysis_status == null) {
            //             res.analysis_status = "pending";
            //             res.analysis_remaining = 0;
            //         }
            //     });
            // }

            if (this.currentTab == "manual" && this.getAutoAnalyze) {
                this.handleAutoAnalysis();
                this.resultData.data.forEach((res) => {
                    if (this.getAutoAnalyze && res.analysis_status == null) {
                        res.analysis_status = "pending";
                        res.analysis_remaining = 0;
                    }
                });
            }
            this.isLoading = false;
            const that = this;
            if (!this.isLoading) {
                let data = this.analyzerOptions.find((el) => (el.analysis && el.name === "Case Identifiers") || (el.analysis && el.name === "Negative Words") || (el.analysis && el.name === "Product Keywords"));
                if (data?.analysis)
                    setTimeout(async () => {
                        await that.highlightItems();
                    }, 5000);
            }
            // if(!this.isLoading) setTimeout(async ()=>{await that.highlightItems(); this.highlighting = !this.highlighting},2000);
        },

        async addSerpData(filters = false, totalCount = false) {
            if (this.currentTab === "prebuilt" || this.prebuiltClicked) {
                await this.fetchSerpData(this.prebuiltQuery, this.prebuiltData.data, false, filters, totalCount);
            } else {
                await this.fetchSerpData(this.currentTab === "manual" ? this.manualSearchQuery : this.searchKeyword, this.resultData.data, true, false, totalCount);
            }
            if ((this.prebuiltData?.data && this.prebuiltData?.data.length > 0) || (this.caseResultData?.data && this.caseResultData?.data.length > 0)) {
                if (this.currentTab !== "manual") {
                    this.handleLinkClicked(null, this.currentTab == "prebuilt" ? this.prebuiltData?.data : this.caseResultData?.data, this.currentTab);
                }
            }
        },

        async handleTabChange(tab) {
            this.tabChanged = true;
            if (this.prebuiltClicked && tab !== "prebuilt") {
                this.clickEvent(this.tree);
            }
            this.currentTab = tab;
            this.expanded = true;
            this.expandedCaseQueries = true;
            this.grouped = false;
            this.page_number = 1;
            this.toggleFilter = false;
            this.count += 1;
            if (tab == "case_query") {
                this.isLoading = true;
                this.caseEntityId = [];
                await this.fetchCaseQueriesData();
                this.handleExpandAll("case_query");
                this.isLoading = false;
            } else {
                this.handleExpandAll("searchEngine");
                this.filters.forEach((res) => {
                    if (res.dataFetched) {
                        res.dataFetched = false;
                        res.data = [];
                    }
                });
            }
            // await this.clickEvent(this.tree);
            this.$nextTick(() => {
                let queryTree = tab == "prebuilt" ? this.$refs.searchEngine?.$options.propsData.items.children : this.$refs.case_query?.$options.propsData.items.children;
                queryTree?.forEach((ele) =>
                    ele.children?.forEach((ele1) => {
                        {
                            if (ele1.query == this.tree.query && this.currentTab == "case_query") {
                                this.$refs.case_query.selectedRow = ele1.id;
                                this.clickEvent(this.tree);
                                this.handleLinkClicked(null, this.currentTab == "prebuilt" ? (this.prebuiltData?.data?.length > 0 ? this.prebuiltData?.data : []) : this.caseResultData?.data?.length > 0 ? this.caseResultData?.data : [], this.currentTab);
                            } else if (ele1.query == this.displayQuery[this.currentTab] && this.currentTab == "prebuilt") {
                                this.$refs.searchEngine.selectedRow = ele1.id;
                                this.handleLinkClicked(null, this.currentTab == "prebuilt" ? (this.prebuiltData?.data?.length > 0 ? this.prebuiltData?.data : []) : this.caseResultData?.data?.length > 0 ? this.caseResultData?.data : [], this.currentTab);
                            } else {
                                this.handleLinkClicked(null, this.currentTab == "prebuilt" ? (this.prebuiltData?.data?.length > 0 ? this.prebuiltData?.data : []) : this.caseResultData?.data?.length > 0 ? this.caseResultData?.data : [], this.currentTab);
                            }
                        }
                    })
                );
            });
            setTimeout(() => {
                if (this.$refs.case_query?.$children) {
                    this.handleExpandAll("case_query");
                } else if (this.$refs.searchEngine?.$children) {
                    this.handleExpandAll("searchEngine");
                }
            }, 500);

            this.lazyLoading = false;
            this.isLoading = false;
            // this.monitoringFilterVal = {
            //   name: "All",
            //   code: "all",
            //   type: "MonitoringFilter",
            // };
            // this.visitedFilterVal = {
            //   name: "All",
            //   code: "all",
            //   type: "VisitedFilter",
            // };
        },
        async handleGetMoreResult(manualQuery = false) {
            if (this.isLoading) return;
            if (this.tree || manualQuery) {
                this.loadMore = true;
                let bodyData = {
                    query: manualQuery ? this.manualSearchQuery : this.tree.query,
                    start: manualQuery ? this.resultData.next : this.prebuiltData.next,
                    request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                    country_code: this.currentTab == "manual" ? "" : this.selectedCountryFilter ? this.selectedCountryFilter.value : "",
                    country_cr_code: this.currentTab == "manual" ? "" : this.selectedCountryFilter?.option ? this.selectedCountryFilter.option : "",
                };
                this.isLoading = true;
                const {data} = await osintAxios.post("/search-engine", bodyData, {headers: {"x-query-id": this.tree.id, "x-tool-name": "search-engine-tool"}});
                if (data?.data?.length > 0) {
                    this.deepScanManual = false;
                    this.deepScanManualDone = false;
                    // this.prebuiltData = data;
                    await this.sendDataToDB(data, manualQuery);

                    if (this.prebuiltData?.data) {
                        this.handleLinkClicked(null, this.prebuiltData.data, "prebuilt");
                    }
                } else {
                    await this.sendDataToDB(null, manualQuery);
                    if (!this.deepScanManual && !this.deepScanManualDone) {
                        this.$toast.warning("Initial scan provided no results. Please wait performing deep scan...");
                        this.deepScanManual = true;
                    }
                    if (this.deepScanManualDone) {
                        this.$toast.error("No result found.");
                    }
                }
                if (data?.warning) this.$toast.warning(data.warning);
                this.isLoading = false;
                await this.addSerpData();
                // if (this.getAutoAnalyze) {
                //     await this.handleAutoAnalysis();
                // }
            }
        },

        async handleQuerySearch(tree, filters = false) {
            if (!tree) {
                this.resetSideSelection();
            }
            this.groupedStructure = false;
            this.isClusteringStatus = false;
            this.grouped = false;
            this.placeHolders = null;
            this.viewAllClicked = false;

            document.getElementById("case-list").scrollTo({
                top: 0,
                behavior: "smooth",
            });
            this.lazyLoading = false;
            this.page_number = 1;
            if (this.currentTab == "prebuilt") {
                if (!this.showResultCount) this.showResultCount = {};
                this.showResultCount[this.currentTab] = false;
                this.prebuiltData = [];
                this.prebuiltQuery = tree.query;
                this.displayQuery.prebuilt = tree?.maskedQuery ? tree.maskedQuery : tree.text;
                this.$emit("searched", {data: {query: this.displayQuery.prebuilt}, source: "search-engine"});
                let bodyData = {
                    query: tree.query,
                    request_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                    country_code: this.selectedCountryFilter ? this.selectedCountryFilter.value : "",
                    country_cr_code: this.selectedCountryFilter?.option ? this.selectedCountryFilter.option : "",
                };
                this.isLoading = true;
                if (!this.autorun) {
                    const {data} = await osintAxios.post("/search-engine", bodyData, {headers: {"x-query-id": tree.id, "x-tool-name": "search-engine-tool"}});
                    if (data?.data?.length > 0) {
                        // this.prebuiltData = data;
                        await this.sendDataToDB(data, false);
                        if (this.showAnalyser.length > 0) this.getAllPendingAnalysis();

                        if (this.prebuiltData?.data) {
                            this.handleLinkClicked(null, this.prebuiltData.data, "prebuilt");
                        }
                    } else {
                        await this.sendDataToDB(null, false);
                        this.clickedLinks.push({
                            query: this.displayQuery[this.currentTab],
                            links: [],
                            doneLinks: [],
                            allData: 0,
                            tab: this.currentTab,
                        });
                        this.$toast.warning("Initial scan provided no results. Please wait performing deep scan...");
                    }
                    if (data?.warning) this.$toast.warning(data.warning);
                    if (!this.resultsCount) this.resultsCount = {};
                    this.resultsCount[this.currentTab] = data?.total_results;
                    this.isLoading = false;
                    await this.addSerpData(filters, true);
                } else {
                    this.analysisStatusQueries.forEach((el) => {
                        if (el._id == tree.id) {
                            console.log(el);
                            if (!this.resultsCount) this.resultsCount = {};
                            this.resultsCount[this.currentTab] = el.total_results;
                            this.queriesCount = el.result_count;
                        }
                    });
                    const data = await this.getFilteredData(false);
                    this.prebuiltQuery = tree.query;
                    let total_results = false;

                    if (this.resultsCount[this.currentTab] > 0) total_results = true;
                    await this.fetchSerpData(this.prebuiltQuery, data, false, false, total_results);
                    if (data?.data) {
                        this.handleLinkClicked(null, data.data, "prebuilt");
                    }
                }
            } else {
                this.isLoading = true;
                this.displayQuery.case_query = tree ? (tree.maskedQuery ? tree.maskedQuery : tree.text) : "";
                let countryDetails = [];
                this.caseQueriesData.queries.map((el) => {
                    if (tree.id === el._id) {
                        (countryDetails.countryCode = el.country_code), (countryDetails.countryCrCode = el.country_cr_code);
                    }
                });
                if (this.tabName === "monitoring") {
                    let query = {query_id: tree.id, _id: tree.id};
                    this.SET_SELECTED_TOOL_QUERY({query});
                    let values = await monitoringSearchData(this.case_id, "search-engine-tool", this.$route.params.toolName, this.getMonitoringDateFilter, query);
                    if (!this.resultsCount) this.resultsCount = {};
                    if (!this.showResultCount) this.showResultCount = {};
                    this.resultsCount[this.currentTab] = values.total_results;
                    this.showResultCount[this.currentTab] = true;
                    this.caseResultData = {data: values.values};
                    this.totalResults = values.totalResults;
                    this.isLoading = false;
                    return;
                }

                this.caseResultData = {};
                try {
                    await axios.post("/datapoints/fetch-save-search-engine-results", {
                        search_query: tree.query,
                        masked_query: tree.maskedQuery,
                        category: tree.category.category || tree.category,
                        label: tree.label ? tree.label : tree.text,
                        case_id: this.case_id,
                        country_code: countryDetails.countryCode ? countryDetails.countryCode : "",
                        country_cr_code: countryDetails.countryCrCode ? countryDetails.countryCrCode : "",
                    });
                } catch (error) {
                    console.log(error, "/datapoints/fetch-save-search-engine-results");
                }

                this.caseQuery = {};
                if (this.displayQuery?.case_query)
                    this.$emit("searched", {
                        data: {query: this.displayQuery.case_query},
                        source: "search-engine",
                    });
                this.page_number = 1;
                this.fetchedPages = [];
                await this.fetchAnalysisData({}, false, filters);
                if (this.caseResultData?.data) {
                    this.handleLinkClicked(null, this.caseResultData.data, "case_query");
                }
                if (tree)
                    this.$emit("searchResult", {
                        data: this.caseResultData?.data,
                        source: "search-engine",
                        searchType: "query",
                    });
                this.isLoading = false;
            }
            this.isLoading = false;
        },

        async clickEvent(tree, filters = false, analyze = true, loader = true) {
            this.handelUpdateStatus.tree = tree;
            this.loadMore = false;
            this.filtersApplied = this.filterApplied();
            let treelength = 0;
            let childrenExpanded = 0;
            this.$refs["searchEngine"]?.$children?.[0]?.$children.forEach((ele) => {
                if (!ele.leaf) {
                    treelength++;
                }
                if (ele._props.tree.text === tree.text && ele.expanded) {
                    this.expanded = false;
                }
            });
            this.$refs["searchEngine"]?.$children?.[0]?.$children.forEach((ele) => {
                if (ele._props.tree.text === tree.text && ele.expanded && ele.$children?.length > 0) {
                    childrenExpanded = childrenExpanded - 1;
                } else if (ele.expanded && ele.$children?.length > 0) {
                    childrenExpanded = childrenExpanded + 1;
                }
            });
            if (treelength === childrenExpanded + 1) {
                this.expanded = true;
            }
            if (!filters) {
                this.filters.forEach((res) => {
                    if (res.name != "matchingIdentifiers") {
                        res.data = [];
                        res.page = 1;
                        res.dataFetched = false;
                    }

                    if (res.name == "matchingIdentifiers") {
                        res.data = [0, 40];
                    }
                });
                this.toggleFilter = false;
                this.filtersApplied = false;
            }
            if (tree?.query) {
                this.tree = tree;
                if (this.currentTab === "prebuilt") {
                    this.searchAllNext = {
                        query: tree?.query,
                        value: 1,
                    };
                    this.clickedLinks = this.clickedLinks?.filter((ele) => ele?.query !== tree?.text);
                }
                this.searchEngineQueriesLoading = true;
                try {
                    await this.handleQuerySearch(tree, filters);
                    await this.addAuditLog();
                    // if (this.getAutoAnalyze && analyze) {
                    //     await this.handleAutoAnalysis();
                    // }
                    this.analyzerOptions.forEach((el) => {
                        if (el.name !== "Case Identifiers" && el.name !== "Negative Words" && el.name !== "Product Keywords" && el.analysis) {
                            this.analyzerCount = ++this.analyzerCount;
                        }
                    });
                    sessionStorage.setItem(
                        "analyzer-count",
                        JSON.stringify({
                            analyzerCount: this.analyzerCount,
                        })
                    );

                    if (this.analyzerOptions.some((el) => el.analysis === true && el.name !== "Case Identifiers" && el.name !== "Negative Words" && el.name !== "Product Keywords" && this.autorun)) {
                        await this.handleAutoAnalysis();
                    }
                } catch (error) {
                    console.log(error);
                }
                this.searchEngineQueriesLoading = false;
            }
        },
        getTag(tag) {
            return {
                name: tag,
                value: tag,
                code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
        },
        getAllPendingAnalysis() {
            const filterArr = this.prebuiltData.data.filter((el) => el.analysis_status === "pending").map((el) => el._id);
            if (filterArr.length > 0) {
                this.startAnalyzerId = [...filterArr];
            }
        },

        handleExpandAll(refer) {
            let expandedRef = "";
            if (refer == "searchEngine") {
                this.expanded = !this.expanded;
                expandedRef = this.expanded;
            } else {
                this.expandedCaseQueries = !this.expandedCaseQueries;
                expandedRef = this.expandedCaseQueries;
            }
            this.$refs[refer]?.$children?.[0]?.$children.forEach((ele) => {
                ele.expanded = expandedRef;
                if (ele.$children.length > 0) {
                    ele.$children.forEach((elem) => {
                        elem.expanded = expandedRef;
                    });
                }
                [...ele.$el.getElementsByClassName("panel expandible panel-transition")]?.forEach((e) => (e.style.maxHeight = expandedRef ? "fit-content" : "0"));
            });
        },
        handleClear() {
            this.toggleFilter = false;
            this.displayQuery = {
                prebuilt: "",
                manual: "",
                case_query: "",
            };
            this.tabChanged = true;
            if (this.selectedEntity === "person") {
                this.fNameInput = "";
                this.mNameInput = "";
                this.lNameInput = "";
                this.nickNameInput = "";
                this.fullNameInput = "";
                this.locationInput = [];
                this.educationInput = [];
                this.employmentInput = [];
                this.rolesInput = [];
                this.hobbiesInput = [];
                this.emailInput = "";
                this.phoneInput = "";
                this.usernameInput = "";
                this.companyInput = [];
                this.domainInput = "";
                this.selectedCountryFilter = [];
                this.selectedItem = {
                    email: null,
                    phone: null,
                    username: null,
                    company: [],
                    location: [],
                    education: [],
                    employment: [],
                    hobby: [],
                };
                this.prebuiltData = {};
                this.selectedEntityId = {};
                this.selectedLanguageFilter = [{value: "English"}];
                this.SET_BUILD_QUERIES(false);
                sessionStorage.removeItem("search-engine-inputs");
                sessionStorage.removeItem("search-engine-build-queries");
                sessionStorage.removeItem("search-engine-selected-queries");
                if (this.$refs["analysis-tool-input-email"]?.search) this.$refs["analysis-tool-input-email"].search = "";
                if (this.$refs["analysis-tool-input-phone"]?.search) this.$refs["analysis-tool-input-phone"].search = "";
                if (this.$refs["analysis-tool-input-username"]?.search) this.$refs["analysis-tool-input-username"].search = "";
                if (this.$refs["analysis-tool-input-company"]?.search) this.$refs["analysis-tool-input-company"].search = "";
                if (this.$refs["analysis-tool-input-domain"]?.search) this.$refs["analysis-tool-input-domain"].search = "";
                if (this.$refs["analysis-tool-input"][0]) {
                    this.$refs["analysis-tool-input"][0].search = "";
                    this.$refs["analysis-tool-input"][0].value = "";
                    this.$refs["analysis-tool-input"][0].selectedItem = null;
                }
            }
            if (this.selectedEntity === "company") {
                this.companyInput = [];
                this.selectedCompanyItem = {
                    email: null,
                    phone: null,
                    company: [],
                    domain: null,
                    location: [],
                    website: [],
                };
                this.selectedItem["company"] = [];
                this.companyemailInput = "";
                this.companyphoneInput = "";
                this.domainInput = "";
                this.websiteInput = "";
                this.companyCountryFilter = [];
                this.companyLanguageFilter = [{value: "English"}];
                sessionStorage.removeItem("search-engine-inputs");
                sessionStorage.removeItem("search-engine-build-queries");
                sessionStorage.removeItem("search-engine-selected-queries");
                // this.$refs["analysis-tool-input-company-email"][0].search = "";
            }
            // this.buildQueries = false;
        },
        /**
         *
         * @param {String} link => link of searched query clicked
         * @param {Array} data => Result data
         * @param {String} query_name => tab name e.g. prebuilt, manual or case_query
         * @param {String} id => search result id
         */
        async handleLinkClicked(link, data, query_name, id) {
            if (this.getReadOnlyMode) return;
            let index = this.clickedLinks.findIndex((clickedLink) => clickedLink.query == this.displayQuery[query_name] && clickedLink.tab == this.currentTab);
            if (index != -1) {
                if (link && !this.clickedLinks.find((ele) => ele.links.includes(link))) {
                    this.clickedLinks[index].links.push(link);
                }
                if (link && !this.clickedLinks.find((ele) => ele.doneLinks.includes(link))) {
                    this.clickedLinks[index].doneLinks.push(link);
                }
            } else {
                this.clickedLinks.push({
                    query: this.displayQuery[query_name],
                    links: link ? [link] : [],
                    doneLinks: link ? [link] : [],
                    allData: data.length,
                    tab: this.currentTab,
                });
                index = this.clickedLinks.findIndex((ele) => ele.query == this.displayQuery[query_name] && ele.tab == this.currentTab);
            }
            let similarLinks = [];
            let similarDoneLinks = [];
            this.clickedLinks.forEach((n) => {
                n.links.forEach((n1) => {
                    const list = data?.map((n1) => n1.link);
                    if (list?.length > 0 && list.includes(n1)) {
                        similarLinks.push(n1);
                    }
                });
                n.doneLinks.forEach((n1) => {
                    const list = data?.map((n1) => n1.link);
                    if (list?.length > 0 && list.includes(n1)) {
                        similarDoneLinks.push(n1);
                    }
                });
            });

            if (similarLinks.length > 0) {
                similarLinks.forEach((ele) => {
                    if (!this.clickedLinks[index].links.includes(ele)) {
                        this.clickedLinks[index].links.push(ele);
                    }
                });
            }
            if (similarDoneLinks.length > 0) {
                similarDoneLinks.forEach((ele) => {
                    if (!this.clickedLinks[index].doneLinks.includes(ele)) {
                        this.clickedLinks[index].doneLinks.push(ele);
                    }
                });
            }
            this.clickedLinks[index].allData = data?.length;
            const visitedCount = data.filter((n) => n.visited || n.done);
            visitedCount.forEach((ele) => {
                if (!this.clickedLinks[index].links.includes(ele.link) && ele.visited) {
                    this.clickedLinks[index].links.push(ele.link);
                }
                if (!this.clickedLinks[index].doneLinks.includes(ele.link) && ele.done) {
                    this.clickedLinks[index].doneLinks.push(ele.link);
                }
            });
            // this.$forceUpdate();
            if (id) {
                switch (query_name) {
                    case "case_query":
                        const case_index = this.caseResultData?.data?.findIndex((n) => n._id == id);
                        if (case_index !== -1) {
                            this.caseResultData.data[case_index].visited = true;
                            this.caseResultData.data[case_index].done = true;
                        }
                        break;
                    case "manual":
                        const manual_index = this.resultData?.data?.findIndex((n) => n._id == id);
                        if (manual_index !== -1) {
                            this.resultData.data[manual_index].visited = true;
                            this.resultData.data[manual_index].done = true;
                        }
                        break;
                    case "prebuilt":
                        const prebuilt_index = this.prebuiltData?.data?.findIndex((n) => n._id == id);
                        if (prebuilt_index !== -1) {
                            this.prebuiltData.data[prebuilt_index].visited = true;
                            this.prebuiltData.data[prebuilt_index].done = true;
                        }
                        break;

                    default:
                        break;
                }
                await axios.post("/datapoints/set-visited-status", {
                    case_id: this.$route.params.id,
                    search_result_object_id: [id],
                    visited_status: true,
                    done: true,
                });
                if (this.currentTab === "case_query") {
                    await this.fetchCaseQueriesData();
                }
            }
        },
        async handleMonitoringStatus(event) {
            this.searchEngineQueriesLoading = true;
            const index = this.caseQueriesData.queries.findIndex((n) => n._id == event.id);
            if (index != -1 && !this.getReadOnlyMode) {
                this.caseQueriesData.queries[index].monitoring = event.monitoring;
                this.$forceUpdate();
            }
            this.handleMaxHeight();
            await axios.put("/filter-params", {
                case_id: this.$route.params.id,
                search_query: event.query,
                monitoring_status: event.monitoring,
            });
            // await this.fetchCaseQueriesData();

            const query = {
                ...event,
                ...{
                    _id: event.id,
                    monitoring_id: event.monitoring_id,
                    tool: "search-engine-tool",
                },
            };
            this.$emit("updateFrequency", {
                query: query,
                isQuery: true,
                maskedQuery: query.text,
            });

            let inherit_settings = this.monitoringTools["search-engine-tool"] && this.monitoringTools["search-engine-tool"].status === "enabled" && !this.monitoringTools["search-engine-tool"].has_inner_level_monitoring ? this.monitoringTools["search-engine-tool"] : this.productMonitoring.find((product) => product.tool === "search-engine-tool");
            this.$emit("updateMonitoring", {
                frequency: inherit_settings?.frequency,
                monitoring_status: event.monitoring ? "enabled" : "disabled",
            });
            this.searchEngineQueriesLoading = false;
        },
        async fetchCaseQueriesData(notFetchResults) {
            if (!notFetchResults) {
                this.searchEngineQueriesLoading = true;
                if (!this.isMonitoringTab) {
                    this.isClusteringStatus = true;
                }
            }
            let reqBody = {case_id: this.case_id, run_status: this.run_status, done_status: this.doneFilterVal, visited_status: this.visitedFilterVal, adverse: this.params.adverse, entity_ids: this.caseEntityId};
            if (this.selectedToolsUsers.length > 0) {
                const userIds = this.selectedToolsUsers.map((user) => ({user_id: user.id}));
                reqBody.user_ids = userIds;
            }
            if (this.end_date && this.start_date) {
                reqBody.end_date = this.end_date ? new Date(this.end_date) : null;
                reqBody.start_date = this.start_date ? new Date(this.start_date) : null;
            }
            const {data} = await axios.post(`/search-engine/queryset/queries`, reqBody);
            const queryset_status = Object.values(data.queryset_status);
            this.caseQueriesData = data;
            if (!this.isMonitoringTab && !notFetchResults) {
                if (queryset_status.length > 0) {
                    if (this.statusKeys(data.queryset_status)) {
                        this.page_number = 1;
                        this.fetchedPages = [];
                        await this.fetchAnalysisData();
                    } else {
                        await this.socketIOConn();
                    }
                } else {
                    this.searchEngineQueriesLoading = false;
                    this.isClusteringStatus = false;
                }
                // if (queryset_status.includes("success")) {
                //     await this.fetchAnalysisData();
                // } else
                // if (queryset_status.includes("pending")) {
                //     this.searchEngineQueriesLoading = true;
                //     this.isClusteringStatus = true;
                // } else {
                //     this.searchEngineQueriesLoading = false;
                //     this.isClusteringStatus = false;
                // }
            } else {
                this.searchEngineQueriesLoading = false;
                this.isClusteringStatus = false;
            }
            // this.searchEngineQueriesLoading = false;
            // if (this.tabName === "tools") {
            //     this.SET_ALL_TOOLS_DATA({
            //         ...this._data,
            //         "tool-name": "search-engine-tool",
            //     });
            // } else {
            //     this.SET_ALL_MONITORING_DATA({
            //         ...this._data,
            //         "tool-name": "search-engine-tool",
            //     });
            // }
        },
        async handleResetFilters() {
            if (this.currentTab == "manual") {
                this.filters.forEach((res) => {
                    if (res.selectAll) {
                        res.selectAll = false;
                    }
                    if (res?.data && res.name != "matchingIdentifiers") {
                        res.data.forEach((doc) => (doc.checked = false));
                    }
                    if (res.name == "matchingIdentifiers") {
                        res.data = [0, 40];
                    }
                });
            } else {
                this.filters.forEach((res) => {
                    if (res.selectAll) {
                        res.selectAll = false;
                    }
                    if (res?.data && res.name != "matchingIdentifiers") {
                        res.data.forEach((doc) => (doc.checked = false));
                    }
                    if (res.name == "matchingIdentifiers") {
                        res.data = [0, 40];
                    }
                });
                this.clickEvent(this.tree, false, false);
            }
        },
        filterApplied() {
            const filterData = this.filters.map((res) => res.data);
            return filterData.some((ele) => ele.find((r) => r.checked || r >= 0));
        },
        async handleApplyFilters() {
            this.page_number = 1;
            this.filtersApplied = this.filterApplied();
            if (this.currentTab == "manual" && this.filtersApplied) {
                this.resultData.data = [];
                const data = await this.getFilteredData(false);
                this.resultData.data = data.data;
                this.getTotalPagesFilter = data.pagination_info.total_pages;
                this.prebuiltClicked = false;
            } else if (this.currentTab == "manual" && !this.filtersApplied) {
                await this.handleSearch();
                this.prebuiltClicked = false;
            } else {
                this.clickEvent(this.tree, true, false);
                if (this.currentTab === "prebuilt") this.prebuiltClicked = true;
            }
        },

        /**
         * Returns the analyzer options required based on given licenses for payload.
         * @return {Object} object required for api payload.
         */
        getAnalyzerOption() {
            let config = {};
            this.getAnalyzerOptions.forEach((res) => {
                if (this.getPermissions.find((perm) => FEATURES.tools[perm] == res.key)) {
                    config[res.payload_name] = res.selected;
                }
            });
            return config;
        },
        /**
         * Handles the auto analysis if auto-analyzer is on.
         */
        async handleAutoAnalysis() {
            this.analysisStart = true;
            const url = `/case-analysis/${this.$route.params.id}/analysis`;
            let recordCount = 0;
            if (this.currentTab == "manual") {
                recordCount = this.resultData.data.length;
            } else if (this.currentTab == "case_query") {
                recordCount = this.total_rows;
            } else {
                recordCount = this.prebuiltData.data.length;
            }
            let payload = {};
            this.analyzerOptions?.forEach((el) => {
                payload[el.payload_name] = el.analysis;
            });
            try {
                await axios.post(url, {
                    record_count: recordCount,
                    tool: "search-engine-tool",
                    queries: [
                        {
                            query_id: this.currentTab == "manual" ? this.manual_queryId : this.tree.id,
                        },
                    ],
                    config: payload,
                });
                this.$toast.success("Analysis started.");
                if (this.prebuiltData?.data?.length || this.caseResultData?.data?.length || this.resultData?.data?.length) {
                    this.startAutoAnalysisStatus();
                }
            } catch (err) {
                this.$toast.error("Something went wrong.");
                this.analysisStart = false;
            }
        },
        async saveSearchData(data, manual) {
            const reqBody = {
                masked_query: manual ? "" : this.tree?.maskedQuery ?? "",
                search_query: manual ? this.manualSearchQuery : this.tree.query,
                category: manual ? "" : this.tree.category,
                label: manual ? this.searchKeyword : this.tree.text,
                case_id: this.$route.params.id,
                data: data?.data
                    ? data?.data?.map((ele) => ({
                          case_id: this.$route.params.id,
                          title: ele?.title,
                          snippet: "snippet" in ele ? ele.snippet : "",
                          link: ele?.link,
                          thumbnail: ele?.pagemap?.cse_image?.length > 0 && ele?.pagemap?.cse_image[0].src ? ele?.pagemap?.cse_image[0].src : "",
                          source: "google",
                          formatted_url: ele?.formattedUrl,
                      }))
                    : [],
                // analysis_config: this.getAnalyzerOption(),
            };
            let config = {};
            this.analyzerOptions?.forEach((el) => {
                if (el.name !== "Case Identifiers" && el.name !== "Negative Words" && el.name !== "Product Keywords") config[el.payload_name] = el.analysis;
            });
            reqBody.analysis_config = this.currentTab === "prebuilt" ? config : this.getAnalyzerOption();
            try {
                const response = await axios.post("/datapoints/search-engine-results", reqBody);
                return response;
            } catch (error) {
                this.$toast.error("Something went wrong");
            }
        },

        async sendDataToDB(data, manual) {
            const {data: searchEngineData} = await this.saveSearchData(data, manual);
            if (data) {
                if (manual) {
                    if (this.resultData?.data) {
                        if (searchEngineData?.data) {
                            searchEngineData.data.forEach((searchItem) => {
                                let foundObj = this.resultData.data.find((resultItem) => resultItem.link === searchItem.link);
                                if (!foundObj) {
                                    this.resultData.data.push(searchItem);
                                }
                            });
                        }
                        // this.resultData.data = this.resultData.data.concat(searchEngineData?.data);
                    } else {
                        this.resultData = searchEngineData;
                    }
                    this.$emit("searchResult", {
                        data: this.resultData?.data,
                        source: "search-engine",
                        searchType: "query",
                    });

                    this.resultData.next = data.next;
                } else {
                    if (this.prebuiltData?.data) {
                        if (searchEngineData?.data) {
                            searchEngineData.data.forEach((searchItem) => {
                                let foundObj = this.prebuiltData.data.find((prebuiltItem) => prebuiltItem.link === searchItem.link);
                                if (!foundObj) {
                                    this.prebuiltData.data.push(searchItem);
                                }
                            });
                        }
                        // this.prebuiltData.data = this.prebuiltData.data.concat(searchEngineData?.data);
                    } else {
                        this.prebuiltData = searchEngineData;
                    }
                    this.$emit("searchResult", {
                        data: this.prebuiltData?.data,
                        source: "search-engine",
                        searchType: "query",
                    });

                    this.prebuiltData.next = data.next;
                }
            }
            // if (this.tabName === "tools") {
            //     this.SET_ALL_TOOLS_DATA({
            //         ...this._data,
            //         "tool-name": "search-engine-tool",
            //     });
            // } else {
            //     this.SET_ALL_MONITORING_DATA({
            //         ...this._data,
            //         "tool-name": "search-engine-tool",
            //     });
            // }
        },
        fetchLimit(item, queryName, tab) {
            const doneLinks = this.clickedLinks.find((ele) => ele.query == item[queryName] && ele.tab == tab)?.doneLinks;
            const allData = this.clickedLinks.find((ele) => ele.query == item[queryName] && ele.tab == tab)?.allData;
            if (allData == 0) {
                return (doneLinks?.length > 0 || allData == 0) && doneLinks.length <= allData ? `${doneLinks.length}/${allData}` : "";
            } else return (doneLinks?.length > 0 || allData) && doneLinks.length <= allData ? `${doneLinks.length}/${allData}` : "";
        },
        handleMaxHeight() {
            //For expanded panel height update
            [...document.getElementsByClassName("panel expandible panel-transition")].filter((ele) => ele?.style?.getPropertyValue("max-height") && ele?.style?.getPropertyValue("max-height") != "0px")?.forEach((e) => (e.style.maxHeight = "fit-content"));
        },
        async handleMarkAsDone({target}, id, link, searchedData, noCall) {
            if (!searchedData.visited) {
                if (!target.checked) {
                    this.clickedLinks.forEach((ele, i) => {
                        let index = ele.doneLinks.findIndex((n) => n == link);
                        if (index != -1) {
                            this.clickedLinks[i].doneLinks.splice(index, 1);
                        }
                    });
                } else {
                    let index = this.clickedLinks.findIndex((ele) => ele.query == this.displayQuery[this.currentTab] && ele.tab == this.currentTab);
                    if (index != -1) {
                        if (link && !this.clickedLinks.find((ele) => ele.doneLinks.includes(link))) {
                            this.clickedLinks[index].doneLinks.push(link);
                        }
                    } else {
                        this.clickedLinks.push({
                            query: this.displayQuery[this.currentTab],
                            links: link ? [] : [],
                            doneLinks: link ? [link] : [],
                            allData: this.searchedData.length,
                            tab: this.currentTab,
                        });
                        index = this.clickedLinks.findIndex((ele) => ele.query == this.displayQuery[this.currentTab] && ele.tab == this.currentTab);
                    }
                }

                const search_id = this.isMonitoringTab ? "monitoring_id" : "_id";
                const searchedDataIndex = this.searchedData.findIndex((n) => n[search_id] == id || n["_id"] == id);
                this.searchedData[searchedDataIndex].done = target.checked;
                this.searchedData[searchedDataIndex].changes_viewed = target.checked;
                this.searchedData[searchedDataIndex]._id = id;
                let prebuiltData = cloneDeep(this.prebuiltData);
                let resultData = cloneDeep(this.resultData);
                let caseResultData = cloneDeep(this.caseResultData);

                const prebuiltIndex = prebuiltData?.data?.findIndex((n) => n._id == id);
                if ((prebuiltIndex && prebuiltIndex !== -1) || prebuiltIndex == 0) {
                    prebuiltData.data[prebuiltIndex].done = target.checked;
                    prebuiltData.data[prebuiltIndex].isDoneLoading = true;
                    this.prebuiltData = prebuiltData;
                }
                const resultIndex = resultData?.data?.findIndex((n) => n._id == id);
                if ((resultIndex && resultIndex !== -1) || resultIndex == 0) {
                    resultData.data[resultIndex].done = target.checked;
                    resultData.data[resultIndex].isDoneLoading = true;
                    this.resultData = resultData;
                }
                const caseIndex = caseResultData?.data?.findIndex((n) => n[search_id] == id || n["_id"] == id);
                if ((caseIndex && caseIndex !== -1) || caseIndex == 0) {
                    caseResultData.data[caseIndex].done = target.checked;
                    caseResultData.data[caseIndex].changes_viewed = target.checked;
                    caseResultData.data[caseIndex].isDoneLoading = true;
                    this.caseResultData = caseResultData;
                }

                this.count += 1;

                this.$forceUpdate();
                if (id && !noCall) {
                    this.isDoneLoading = true;
                    if (this.isMonitoringTab) {
                        let cardInfo = [];
                        cardInfo.push({doc_id: id, sources: ["search-engine-tool"], cascaded: false});
                        await getMarkAsDone(cardInfo, this.$route.params.id, target.checked);
                        await this.getNewMonitoringEvents();
                        await this.getQueryMonitoringEvents("search-engine-tool");
                    } else {
                        await axios.post("/datapoints/set-visited-status", {
                            case_id: this.$route.params.id,
                            search_result_object_id: [id],
                            done: target.checked,
                        });
                        if (this.currentTab === "case_query") {
                            await this.fetchCaseQueriesData(true);
                        }
                    }
                    this.isDoneLoading = false;
                }

                if ((prebuiltIndex && prebuiltIndex !== -1) || prebuiltIndex == 0) {
                    prebuiltData.data[prebuiltIndex].isDoneLoading = false;
                    this.prebuiltData = prebuiltData;
                }
                if ((resultIndex && resultIndex !== -1) || resultIndex == 0) {
                    resultData.data[resultIndex].isDoneLoading = false;
                    this.resultData = resultData;
                }
                if ((caseIndex && caseIndex !== -1) || caseIndex == 0) {
                    caseResultData.data[caseIndex].isDoneLoading = false;
                    this.caseResultData = caseResultData;
                }
                let data = this.analyzerOptions.find((el) => (el?.analysis && el.name === "Case Identifiers") || (el?.analysis && el.name === "Negative Words") || (el?.analysis && el.name === "Product Keywords"));
                if (data?.analysis)
                    setTimeout(async () => {
                        await this.highlightItems();
                    }, 3000);
            }
        },
        async handleMarkAllAsDone(event) {
            const search_id = this.isMonitoringTab ? "_id" : "_id";
            this.isDoneLoading = true;
            for (const ele of this.paginatedSearchedData) {
                if (!(ele.visited || this.clickedLinks.find((ele1) => ele1.links.includes(ele.link)))) {
                    await this.handleMarkAsDone(event, ele[search_id], ele.link, ele, true);
                }
            }
            const paginatedData = this.paginatedSearchedData.filter((el) => !el.visited);
            if (this.isMonitoringTab) {
                let cardInfo = [];
                this.paginatedSearchedData.map((srchData) => {
                    cardInfo.push({doc_id: srchData[search_id], sources: ["search-engine-tool"], cascaded: false});
                });
                await getMarkAsDone(cardInfo, this.case_id, event.target.checked);
                await this.getQueryMonitoringEvents("search-engine-tool");
                await this.getNewMonitoringEvents();
            } else {
                await axios.post("/datapoints/set-visited-status", {
                    case_id: this.$route.params.id,
                    search_result_object_id: paginatedData.map((srchData) => srchData[search_id]),
                    done: event.target.checked,
                });
                if (this.currentTab === "case_query") {
                    await this.fetchCaseQueriesData(true);
                }
                let data = this.analyzerOptions.find((el) => (el?.analysis && el.name === "Case Identifiers") || (el?.analysis && el.name === "Negative Words") || (el?.analysis && el.name === "Product Keywords"));
                if (data?.analysis)
                    setTimeout(async () => {
                        await this.highlightItems();
                    }, 1500);
            }
            this.isDoneLoading = false;
            // this.fetchedPages = this.fetchedPages.filter((page) => page !== this.page_number);
            // await this.fetchAnalysisData();
            // await this.fetchCaseQueriesData();
        },
        resetSideSelection() {
            if (this.$refs.case_query?.setSelected && {}.toString.call(this.$refs.case_query.setSelected) === "[object Function]") {
                this.$refs.case_query.setSelected("");
            }
        },
        onSelect(event, type) {
            console.log(event);
            this.$set(this.selectVal, type, event.value);
            this.$refs["analysis-tool-input-" + type].search = event.value;
            this[`${type}Input`] = event.value;
        },
        onCompanySelect(event, type) {
            this.$set(this.selectCompanyVal, type, event.value);
            this.$refs["analysis-tool-input-company" + type].search = event.value;
            this[`company${type}Input`] = event.value;
        },
        close(type) {
            this.selectedItem[type] = {
                value: this.selectVal[type],
            };
            this.$refs["analysis-tool-input-" + type].search = this.selectVal[type];
            this[`${type}Input`] = this.selectVal[type];
        },
        handleChange(event, type) {
            this.$set(this.selectVal, type, event);
            this[`${type}Input`] = event;
        },
        closeCompany(type) {
            this.selectedCompanyItem[type] = {
                value: this.selectCompanyVal[type],
            };
            this.$refs["analysis-tool-input-company-" + type].search = this.selectCompanyVal[type];
            if (type == "email") {
                this.companyemaiInput = this.selectCompanyVal[type];
            } else if (type == "phone") {
                this.companyphoneInput = this.selectCompanyVal[type];
            }
            this[`company${type}Input`] = this.selectCompanyVal[type];
        },
        handleCompanyChange(event, type) {
            this.$set(this.selectCompanyVal, type, event);
            if (type == "email") {
                this.companyemailInput = event;
            } else if (type === "phone") {
                this.companyphoneInput = event;
            }
        },
        async fetchCategoryProduct() {
            const {data} = await axios.get(`/search-engine/product/${this.getProduct.key}/category`);
            if (data.data.length > 0) {
                this.productCategories = data.data.map((prodCat) => ({name: prodCat.category_name, category: prodCat.top_category}));
                let query_obj = {};
                this.productCategories.forEach((prodCat) => {
                    query_obj[prodCat.name] = [];
                    query_obj[prodCat.name].category = prodCat.category;
                });
                this.queriesObj = query_obj;
                this.$forceUpdate();
            }
            this.treeViewLoading = false;
        },
        handleGrouped(value) {
            this.grouped = value;
        },
        handleExpanded(group_index) {
            this.groupedResults[group_index].expanded = !this.groupedResults[group_index].expanded;
            this.$forceUpdate();
        },
        async handlePageChangeFilters(page_number) {
            if (!this.fetchedFilteredPages.includes(page_number)) {
                const data = await this.getFilteredData(false);
                data.data.forEach((res) => {
                    if (this.currentTab == "manual") {
                        this.resultData.data.push({...res, page: page_number});
                    } else if (this.currentTab == "prebuilt") {
                        this.prebuiltData.data.push({...res, page: page_number});
                    } else {
                        this.caseResultData.data.push({...res, page: page_number});
                    }
                });
                this.fetchedFilteredPages.push(page_number);
            }
        },
        async handlePageChange(page_number) {
            if (this.currentTab === "case_query") {
                this.page_number = page_number;
                await this.fetchAnalysisData();
                this.startAutoAnalysisStatus();
            }
            if (this.isMonitoringTab && this.caseResultData.data.length <= Math.ceil(page_number * 10) && this.caseResultData.data.length != this.totalResults) {
                this.handleMonitoringPages();
            }
            const container = document.querySelector(`.compare__content`);
            if (container)
                container.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            let data = this.analyzerOptions.find((el) => (el?.analysis && el.name === "Case Identifiers") || (el?.analysis && el.name === "Negative Words") || (el?.analysis && el.name === "Product Keywords"));
            if (data?.analysis)
                setTimeout(async () => {
                    await this.highlightItems();
                }, 3000);
        },
        async handleMonitoringPages() {
            let offset = this.caseResultData.data.length;
            if (this.tabName === "monitoring") {
                let query = {query_id: this.tree.id, _id: this.tree.id};
                this.SET_SELECTED_TOOL_QUERY({query});
                let values = await monitoringSearchData(this.case_id, "search-engine-tool", this.$route.params.toolName, this.getMonitoringDateFilter, query, offset);
                this.caseResultData.data = this.caseResultData.data?.concat(values.values);
                this.isLoading = false;
                return;
            }
        },
        // closeUngroupedFilter() {
        //     let el = this.$refs["analyse-tippy"];
        //     el.tippy().hide();
        // },
        async setFilters(docs = {}) {
            this.start_date = docs?.start_date ?? "";
            this.end_date = docs?.end_date ?? "";
            this.run_status = docs?.queries?.run_status ?? "all";
            this.monitoringFilterVal = docs?.queries?.monitoringFilterVal ?? "all";
            this.visitedFilterVal = docs?.visitedFilterVal ?? "all";
            this.doneFilterVal = docs?.doneFilterVal ?? "all";
            this.params.tags = docs?.tags ?? [];
            this.params.organisations = docs?.organisations ?? [];
            this.params.persons = docs?.persons ?? [];
            this.params.locations = docs?.locations ?? [];
            this.params.adverse = docs?.adverse ?? false;
            this.fetchedPages = [];
            try {
                this.page_number = 1;
                await this.fetchAnalysisData();
            } catch (error) {
                console.log(error, "fetchAnalysisData");
            }
            await this.fetchCaseQueriesData();
        },
        async handleViewAllResults() {
            this.viewAllClicked = true;
            this.fetchedPages = [];
            this.tree = {};
            this.resetSideSelection();
            this.displayQuery = {
                prebuilt: "",
                manual: "",
                case_query: "",
            };
            await this.fetchAnalysisData({}, true);
        },
        getSelectedTopics(nodes) {
            let arr = [];
            for (let child of nodes) {
                if (child.checked) arr.push(child.category);
                if (child.children && Array.isArray(child.children) && child.children.length > 0) arr = [...arr, ...this.getSelectedTopics(child.children)];
            }
            return arr;
        },
        async getFilteredData(viewAll) {
            let req_body = {case_id: this.case_id};
            req_body.end_date = this.end_date ? new Date(this.end_date) : null;
            req_body.start_date = this.start_date ? new Date(this.start_date) : null;
            req_body.visited_status = this.visitedFilterVal ? this.visitedFilterVal : "all";
            req_body.done_status = this.doneFilterVal ? this.doneFilterVal : "all";
            req_body.tags = this.params.tags;
            req_body.organisations = this.filters
                .find((res) => res.name == "organisation")
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
            req_body.locations = this.filters
                .find((res) => res.name == "location")
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
            req_body.persons = this.filters
                .find((res) => res.name == "person")
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
            req_body.adverse = this.params.adverse;
            req_body.limit = this.autorun && this.currentTab === "prebuilt" ? this.queriesCount : 10;
            req_body.current_page = this.page_number;
            if (this.currentTab === "manual") {
                req_body.query_id = this.manual_queryId ?? "";
            } else req_body.query_id = this.tree?.id ?? "";
            req_body.internet_queries = this.filters
                .find((res) => res.name == "internetQueries")
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
            req_body.risk_categories = this.filters
                .find((res) => res.name == "risks")
                ?.data.filter((doc) => doc.checked == true)
                .map((res) => res.key);
            req_body.categories = this.getSelectedTopics(this.filters.find((res) => res.name == "topicClassification")?.data);
            req_body.matching_identifiers_min = this.filters.find((res) => res.name == "matchingIdentifiers").data[0] || 0;
            req_body.matching_identifiers_max = this.filters.find((res) => res.name == "matchingIdentifiers").data[1] || 40;
            if (!viewAll) {
                req_body.query_str = this.tree?.query ?? null;
            }
            let config = {};
            this.analyzerOptions?.forEach((el) => {
                config[el.payload_name] = el.selected;
            });
            req_body.analysis_config = config;
            this.fetchedPages.push(this.page_number);
            const {data} = await axios.post("/search-engine/multiqueryresults", req_body);
            if (this.getAutoAnalyze) {
                data.data.forEach((res) => {
                    if (res.analysis_status == null) {
                        res.analysis_status = "pending";
                        res.analysis_remaining = 0;
                    }
                });
            }
            data.data.forEach((res) => {
                res.page = 1;
            });
            if (data.data.length == 0) {
                // this.$toast.error("No data found.");
                this.multiQueryResultLoading = false;
                this.searchEngineQueriesLoading = false;
                this.isLoading = false;
                // return;
            }
            return data;
        },
        async fetchAnalysisData(docs = {}, viewAll, filters) {
            if (this.isMonitoringTab) {
                return;
            }
            this.querySetInfoText();
            this.groupedResults = [];
            this.multiQueryResultLoading = true;
            if (this.fetchedPages.includes(this.page_number)) {
                this.multiQueryResultLoading = false;
                return;
            } else {
                const data = await this.getFilteredData(viewAll);
                if (data && data.pagination_info) {
                    let {total_rows, current_page} = data.pagination_info;
                    if (current_page === 1) {
                        this.caseResultData = {data: new Array(total_rows).fill({link: "", _id: ""})};
                    }
                    let caseData = cloneDeep(this.caseResultData.data);
                    for (let index = (current_page - 1) * 10; index < current_page * 10; index++) {
                        let calc = (index / 10) * 10 - (current_page - 1) * 10;
                        if (data.data[calc]) {
                            caseData[index] = {...data.data[calc], page: this.page_number};
                        }
                    }
                    this.caseResultData = {data: caseData};
                    if (current_page === 1 && data.data.length <= 0) {
                        this.$toast.error("No results found!");
                    }
                    if (filters) {
                        this.getTotalPagesFilter = data.pagination_info.total_pages;
                        this.caseResultData = {data: data.data};
                        this.fetchedFilteredPages = [];
                    }
                }
                if (this.caseResultData?.data) {
                    this.handleLinkClicked(null, this.caseResultData.data, "case_query");
                }
                if (data) {
                    this.total_rows = data.pagination_info.total_rows;
                    this.multiQueryResultLoading = false;
                }
                const queryset_info = data?.queryset_info;

                if (this.statusKeys(queryset_info) || this.isMonitoringTab) {
                    this.stopPolling = true;
                    this.searchEngineQueriesLoading = false;
                    this.isClusteringStatus = false;
                }

                if (queryset_info?.osint_status === "failed") {
                    this.searchEngineQueriesLoading = false;
                    this.isOsintFailed = true;
                    this.isClusteringStatus = false;
                    this.stopPolling = true;
                }

                // if (Object.values(queryset_info).includes("success")) {
                // }

                const clusters = [...new Set(data.data.map((item) => item.cluster_id))].filter((item) => item);
                clusters?.forEach((cluster, index) => {
                    const results = data.data.filter((elem) => elem.cluster_id == cluster);
                    const labels = results.flatMap((res) => res.labels);
                    let groups = [];
                    if (labels.length > 0) {
                        groups = Object.entries(groupBy(labels, "category"));
                        groups = groups.map((group) => ({label: `${group[0]}(${group[1].length})`}));
                    }

                    const groupRes = {
                        group_name: `Cluster-${index + 1}`,
                        articles: results.length,
                        expanded: false,
                        results,
                        groups,
                    };
                    // {
                    //             name: "education",
                    //             count: 15,
                    //         },
                    // if (result.length > 0) {
                    this.groupedResults.push(groupRes);
                    // }
                });
            }
            this.groupedStructure = true;
        },
        async socketIOConn() {
            // this.getSocketIO.on("tool-search-engine-queryset-clustering", async (data) => {
            //     if (data) await this.fetchAnalysisData();
            // });
            if (this.displayQuery?.case_query) {
                this.displayQuery.case_query = "";
            }
            this.tree = {};
            const interval = setInterval(async () => {
                let reqBody = {
                    case_id: this.case_id,
                    run_status: this.run_status,
                    done_status: this.doneFilterVal,
                    visited_status: this.visitedFilterVal,
                    adverse: this.params.adverse,
                };
                if (this.selectedToolsUsers.length > 0) {
                    const userIds = this.selectedToolsUsers.map((user) => ({user_id: user.id}));
                    reqBody.user_ids = userIds;
                }
                const {data} = await axios.post(`/search-engine/queryset/queries`, reqBody);

                if (!isEqual(data.queryset_status, this.querySetInfo)) {
                    this.querySetInfo = data.queryset_status;
                    await this.fetchAnalysisData();
                }
                if (this.statusKeys(data.queryset_status)) {
                    this.stopPolling = true;
                    this.searchEngineQueriesLoading = false;
                    this.isClusteringStatus = false;
                }

                if (this.stopPolling || (this.currentTab != "case_query" && !this.isMonitoringTab)) {
                    clearInterval(interval);
                }
            }, 5000);
        },
        async handleAnalyse() {
            this.searchEngineQueriesLoading = true;
            this.isClusteringStatus = true;
            this.stopPolling = false;
            await this.socketIOConn();
        },
        checkedInput(data, clickedLinks) {
            return data.done || clickedLinks.find((ele) => ele.doneLinks.includes(data.link)) || data.changes_viewed;
        },
        async refreshPlaceholders() {
            this.placeHolders = null;
            await this.getPlaceholders();
        },
        async getPlaceholders() {
            if (!this.placeHolders) {
                const case_id = this.$route.params.id;
                this.isPlaceholderLoading = true;
                const {data} = await axios.post(`/search-engine/analysis-queries/placeholders`, {
                    case_id,
                    query_str: this?.tree?.query ?? null,
                });
                this.placeHolders = data;
                this.isPlaceholderLoading = false;
            } else return;
        },
        async showUngroupedModal() {
            this.$modal.show("ungrouped-filter");
        },
        // closeUngroupedFilter() {
        //     this.showUngroupedFilterModal = false;
        //     this.isPlaceholderLoading = false;
        // },
        handleExpandCollapse(filterCategory) {
            if (filterCategory === "organisations") {
                this.FilterCategory.organisations = !this.FilterCategory.organisations;
            } else if (filterCategory === "locations") {
                this.FilterCategory.locations = !this.FilterCategory.locations;
            } else if (filterCategory === "persons") {
                this.FilterCategory.persons = !this.FilterCategory.persons;
            }
        },
        querySetInfoText() {
            const querySetMapping = {
                clustering_status: "Data Clustering",
                multilevel_status: "Multilevel Analysis",
                ner_status: "Entity Recognition",
                sentiment_status: "Sentiment Analysis",
                summarization_status: "Summarization",
            };
            let intervalCount = 0;
            const interval = setInterval(() => {
                const status_keys = Object.entries(this.querySetInfo).filter((query) => query[0].includes("status"));
                const query_set = status_keys.filter((keys) => keys[1] === "pending").map((st_key) => st_key[0]);
                let name = query_set[intervalCount];
                if (querySetMapping[name]) {
                    this.loadingState = querySetMapping[name] + " is in progress...";
                } else {
                    this.loadingState = "Loading...";
                }
                // increment our counter
                intervalCount += 1;

                // reset counter if we reach end of array
                if (intervalCount === query_set.length) {
                    intervalCount = 0;
                }
                if (query_set.length <= 0) {
                    clearInterval(interval);
                }
            }, 3000);
            // return query_set;
        },
        statusKeys(queryset_info) {
            return queryset_info.multilevel_status == "success" && queryset_info.clustering_status == "success" && queryset_info.summarization_status == "success" && queryset_info.sentiment_status == "success" && queryset_info.ner_status == "success" && queryset_info.osint_status === "success";
        },
        async addAuditLog() {
            await axios.post("/search-engine/audit", {query_id: this.tree.id, tab_name: this.currentTab, case_id: this.case_id});
        },

        // listen on click event of start analysis
        handleAnalysisStatus(data) {
            this.analysisStatusAbort();
            this.startAnalyzerId.push(data.id);
            this.startStatusInterval();
        },
        // on Click of start analysis icon from card
        // To get the analysis status wether its completed in particular interval in 2 second
        startStatusInterval() {
            if (this.prebuiltData?.data || this.caseResultData?.data || this.resultData?.data) {
                const intervalTime = 3000;
                if (!this.intervalId) {
                    this.intervalId = setInterval(this.getAnalysisStatus, intervalTime);
                } else {
                    clearInterval(this.intervalId);
                    this.intervalId = setInterval(this.getAnalysisStatus, intervalTime);
                }
            }
        },
        // To abort the api request
        analysisStatusAbort() {
            if (cancelPostRequest) {
                cancelPostRequest("cancel request");
            }
        },
        // To get the analysis status wether its completed
        async getAnalysisStatus() {
            const url = `/case-analysis/${this.$route.params.id}/analysis/docs`;
            try {
                const response = await axios.post(url, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelPostRequest = c;
                    }),
                    tool: "search-engine-tool",
                    doc_ids: this.startAnalyzerId,
                });
                if (this.currentTab === "prebuilt") {
                    this.prebuiltData.data = this.setUpdatedStatus(response.data, this.prebuiltData.data);
                }
                if (this.currentTab === "case_query") {
                    this.caseResultData.data = this.setUpdatedStatus(response.data, this.caseResultData.data);
                }
                if (this.currentTab == "manual") {
                    this.resultData.data = this.setUpdatedStatus(response.data, this.resultData.data);
                }

                let isPending = response.data.some((el) => el.analysis_status === "pending" || !el.analysis_status);
                if (!isPending) {
                    clearInterval(this.intervalId);
                    this.startAnalyzerId = [];
                }
            } catch (err) {
                console.error("err", err);
                this.$toast.error("Something went wrong.");
                this.analysisStart = false;
                clearInterval(this.intervalId);
            }
        },
        setUpdatedStatus(pollingResponse, dataAccordingTab) {
            pollingResponse.forEach((el) => {
                dataAccordingTab = dataAccordingTab?.map((item) => {
                    if (item._id === el._id) {
                        return {...item, analysis_progress: el.analysis_progress, analysis_status: el.analysis_status, risk_categories: el.risk_categories};
                    }
                    return item;
                });
            });
            return dataAccordingTab;
        },
        /**
         * TO execute the polling api on auto analyzer
         * watcher on startAnalyzerId to start the auto analysis process in interval
         * just update the ids in startAnalyzer id
         * it will invoke the startStatusInterval function
         */
        startAutoAnalysisStatus() {
            this.startAnalyzerId = [];
            switch (this.currentTab) {
                case "prebuilt": {
                    this.startAnalyzerId = this.prebuiltData.data.map((data) => data._id);
                    break;
                }
                case "case_query": {
                    this.startAnalyzerId = this.caseResultData.data.filter((data) => data._id && data._id !== "").map((el) => el._id);
                    break;
                }
                case "manual": {
                    this.startAnalyzerId = this.resultData.data.map((data) => data._id);
                    break;
                }
            }
        },
    },
};
